import React, { ReactNode, useState } from 'react';
import { Icon } from './icon';
// HTMLInputTypeAttribute

interface InputFieldProps {
  label?: string;
  description?: string;
  name: string;
  error?: string;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  startIconPadding?: number;
  endIconPadding?: number;
}

const InputField: React.FC<
  InputFieldProps & React.HTMLProps<HTMLInputElement>
> = ({
  label,
  description,
  name,
  error,
  type,
  required,
  className,
  startIcon,
  endIcon,
  startIconPadding,
  endIconPadding,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (type === 'number') {
      const value = e.target.value;
      if (Number(value) < 0) {
        return;
      }
    }
    if (rest.onChange) {
      rest.onChange(e);
    }
  };

  return (
    <div className={'inputContainer ' + className}>
      {label && (
        <>
          <label
            className={error && error.length > 0 ? 'errorText' : ''}
            htmlFor={name}
          >
            {label}
            {required && <span className="text-red-error">*</span>}
            <div>
              <span className=" text-xs ">{description}</span>
            </div>
          </label>
        </>
      )}
      <div className="relative">
        <input
          className={error && error.length > 0 ? 'inputError' : ''}
          id={name}
          type={
            type === 'password' ? (showPassword ? 'text' : 'password') : type
          }
          name={name}
          style={{
            paddingLeft: startIcon
              ? startIconPadding
                ? startIconPadding
                : 35
              : 14,
            paddingRight: endIcon ? (endIconPadding ? endIconPadding : 35) : 14,
            marginBottom: '8px',
          }}
          {...rest}
          onChange={handleChange}
        />
        {startIcon && (
          <aside className="absolute left-3 top-1/2 -translate-y-1/2">
            {startIcon}
          </aside>
        )}
        {endIcon && (
          <aside className="absolute right-3 top-1/2 -translate-y-1/2">
            {endIcon}
          </aside>
        )}
        {type === 'password' && (
          <Icon
            shown={showPassword}
            className="absolute text-gray-4 right-3 top-1/2 -translate-y-1/2"
            onClick={() => setShowPassword(!showPassword)}
          />
        )}
      </div>
      {error && error.length > 0 && <div className="error mb-3">{error}</div>}
    </div>
  );
};

export default InputField;
