import React, { useState, useEffect } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { Table, Progress } from 'antd';
import { ArrowLeft2 } from 'iconsax-react';
import ViewAmountSaved from './ViewAmountSaved';
import { viewAmount } from './allProps';
import NoSavingsYet from './NoSavingsYet';
import SavingsProgressNav from './SavingsProgressNav';
import StartSavingModal from '../../../../components/shared/modal/StartSavingsModal';
import SavingsForms from 'components/user/SavingsForm';
import { formatToNaira } from 'functions/currencyConveter';
import { RoutePaths } from 'routes/route-paths';
import { FiCreditCard } from 'react-icons/fi';
import SavingsTabs from 'components/tabs/SavingsTabs';
import { appAxios } from 'api/axios';
import { getTokenDetails } from 'functions/userSession';
import { decodeToken } from 'react-jwt';
import { TokenType } from 'types/jwt';
import OngoingItemsSelectedForSaving from './OngoingItemsSelectedForSaving';
import SavedItems from './SavedItems';
import { ChartData } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from 'chart.js';
import { Loader } from 'lucide-react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { savingsSlice, selectInterestRate } from 'store/slices/savings';

// Register required components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// import {
//   BarChart,
//   Bar,
//   XAxis,
//   YAxis,
//   Tooltip,
//   ResponsiveContainer,
// } from 'recharts';

interface SavingsData {
  key: string;
  name: string;
  maturityDate: string;
  savingsGoal: string;
  goalLevel: number;
}

const savingsData: SavingsData[] = [
  {
    key: '1',
    name: 'My new savings 2',
    maturityDate: '2 Mar, 2025',
    savingsGoal: '₦250,000.00',
    goalLevel: 100,
  },
  {
    key: '2',
    name: 'My new special 1st quarter savings',
    maturityDate: '16 April, 2025',
    savingsGoal: '₦400,000.00',
    goalLevel: 100,
  },
  {
    key: '3',
    name: 'My new savings',
    maturityDate: '25 March, 2025',
    savingsGoal: '₦150,000.00',
    goalLevel: 100,
  },
  {
    key: '4',
    name: 'My new savings 2',
    maturityDate: '2 Mar, 2025',
    savingsGoal: '₦250,000.00',
    goalLevel: 100,
  },
  {
    key: '5',
    name: 'My new special 1st quarter savings',
    maturityDate: '16 April, 2025',
    savingsGoal: '₦400,000.00',
    goalLevel: 100,
  },
  {
    key: '6',
    name: 'My new savings',
    maturityDate: '25 March, 2025',
    savingsGoal: '₦150,000.00',
    goalLevel: 100,
  },
];

const columns = [
  { title: 'Savings Name', dataIndex: 'name', key: 'name' },
  { title: 'Maturity Date', dataIndex: 'maturityDate', key: 'maturityDate' },
  { title: 'Savings Goal', dataIndex: 'savingsGoal', key: 'savingsGoal' },
  {
    title: 'Goal Level',
    dataIndex: 'goalLevel',
    key: 'goalLevel',
    render: (goalLevel: number) => (
      <Progress percent={goalLevel} strokeColor="#4CAF50" showInfo={true} />
    ),
  },
  {
    title: 'Action',
    key: 'action',
    render: () => (
      <a className="text-[#EFA005] font-medium hover:underline">View Details</a>
    ),
  },
];

interface SavingsHistoryResponse {
  success: boolean;
  message: string;
  data: {
    month: number;
    year: number;
    totalSavings: number;
    dailySavings: Array<{
      date: string;
      amount: number;
    }>;
  };
  statusCode: number;
}

function HowJomptVaultWorks() {
  const token = getTokenDetails();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [interestRate, setInterestRate] = useState<number>(0);
  const decodedToken = decodeToken<TokenType>(token!);
  const customerId = decodedToken?.customerId;
  
  const dispatch = useAppDispatch();
  const storeInterestRate = useAppSelector(selectInterestRate);

  const [allSavings, setAllSavings] = useState<[]>([]);
  const [totalSavingsBalance, setTotalSavingsBalance] = useState();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [showSavingsForm, setShowSavingsForm] = useState<boolean>(false);

  const [activeTab, setActiveTab] = useState('ongoing');

  const [savingsHistory, setSavingsHistory] = useState<
    SavingsHistoryResponse['data']
  >({
    month: 0,
    year: 0,
    totalSavings: 0,
    dailySavings: [],
  });

  const handleGoBack = () => {
    navigate(-1); // Go back to the previous route
  };

  const goToCreateSavings = () => {
    navigate(RoutePaths.USER_CREATE_SAVINGS_JOMPVAULT);
  };

  const handleStartSavings = () => {
    setIsModalOpen(false); // Close the modal
    setShowSavingsForm(true); // Show the SavingsForms component
  };

  // Prepare data for the chart
  const chartData: ChartData<'bar'> = {
    labels: savingsHistory.dailySavings.map((item) => item.date),
    datasets: [
      {
        label: 'Amount Saved',
        data: savingsHistory.dailySavings.map((item) => item.amount),
        backgroundColor: '#31005C',
        borderColor: '#31005C',
        borderWidth: 1,
      },
    ],
  };

  const chartOptions: ChartOptions<'bar'> = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: '',
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  useEffect(() => {
    const getAllSavings = async () => {
      try {
        setLoading(true);
        const { data } = await appAxios.get(
          `get-customer-savings?CustomerId=${customerId}`
        );
        if (data && data.statusCode === 200) {
          setAllSavings(data.data);
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    getAllSavings();
  }, [activeTab, activeTab === 'ongoing']);

  useEffect(() => {
    const getTotalSavingsBalance = async () => {
      try {
        setLoading(true);
        const { data } = await appAxios.get(
          `get-total-savings-balance?customerId=${customerId}`
        );
        if (data && data.statusCode === 200) {
          console.log(data, 'data');
          setTotalSavingsBalance(data.data);
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    getTotalSavingsBalance();
  }, []);

  useEffect(() => {
    const getSavingsHistory = async () => {
      try {
        const { data } = await appAxios.get<SavingsHistoryResponse>(
          `get-savings-history?CustomerId=${customerId}`
        );
        if (data.success) {
          setSavingsHistory(data.data);
        }
      } catch (error) {
        console.error('Error fetching savings history:', error);
      }
    };

    getSavingsHistory();
  }, [customerId]);

  useEffect(() => {
    const fetchInterestRate = async () => {
      try {
        // Try to get the interest rate from localStorage first
        const savedInterestRate = localStorage.getItem('jompVaultInterestRate');
        
        if (savedInterestRate) {
          const rate = parseFloat(savedInterestRate);
          setInterestRate(rate);
          dispatch(savingsSlice.actions.setInterestRate(rate));
          return; // Exit if we found a valid rate in localStorage
        }
        
        // If not in localStorage, fetch from API
        const { data } = await appAxios.get(
          'https://backend.jompstart.com/get-savings-type'
        );
        if (data && data.success) {
          const jompVaultData = data.data.find(
            (item: any) => item.name === 'jompVault'
          );
          if (jompVaultData) {
            const rate = jompVaultData.interestRate;
            setInterestRate(rate);
            
            // Save to redux store
            dispatch(savingsSlice.actions.setInterestRate(rate));
            
            // Save to localStorage
            localStorage.setItem('jompVaultInterestRate', rate.toString());
          }
        }
      } catch (error) {
        console.error('Error fetching interest rate:', error);
      }
    };

    fetchInterestRate();
  }, [dispatch]);

  return (
    <div className="p-6 bg-[#F8F9FC] min-h-screen">
      <div className="flex flex-col gap-y-4">
        {/* Back Button */}
        <button
          onClick={handleGoBack}
          className="flex items-center gap-2 text-[#EFA005]"
        >
          <ArrowLeft2 />
          Back
        </button>

        <div className="flex flex-col justify-between items-center md:flex-row lg:flex-row">
          <div className="mb-4 lg:mb-0">
            <h1 className="text-2xl font-semibold">Savings</h1>
            <p>
              Reach your unique individual saving goals for 45 to 90 days with{' '}
              {interestRate}% interest P.A
            </p>
          </div>

          <button
            className="bg-[#EFA005] text-white rounded-md w-48 px-5 py-4"
      
            onClick={goToCreateSavings}
          >
            Create Savings
          </button>
        </div>
      </div>

      {/* Header */}
      {/* <div>
        <h1 className="text-2xl font-semibold text-gray-900">Savings</h1>
        <p className="text-gray-600">
          Reach your unique individual saving goals for 45 to 90 days with 25%
          interest P.A
        </p>
      </div> */}

      {/* Cards */}
      <div className="grid grid-cols-2 gap-4 mt-6">
        <div className="flex flex-col gap-3">
          <div className="p-6 h-1/2 text-white bg-gradient-to-r from-purple-400 to-indigo-600 rounded-lg">
            <h3 className="text-lg font-medium">Total Savings Balance</h3>
            {loading ? (
              <Loader className="animate-spin" />
            ) : (
              <p className="text-2xl font-semibold">
                {formatToNaira(totalSavingsBalance) || 0}
              </p>
            )}
          </div>
          <div className="bg-[#EFA005] h-1/2 p-6 rounded-lg text-white">
            <p className="text-sm">
              This is all you have earned based on all your savings. To earn
              more, keep saving!
            </p>
            <p className="text-2xl font-semibold">₦0.00</p>
          </div>
        </div>
        {/* Savings History */}
        <div className="px-6 py-3 bg-white rounded-lg shadow-md">
          <div className="flex justify-between items-center">
            <h3 className="text-lg font-semibold">Savings History</h3>
            <button className="px-3 py-1 text-gray-600 rounded-md border">
              This Month
            </button>
          </div>
          {/* <ResponsiveContainer width="100%" height={150}>
            <BarChart
              data={savingsHistoryData}
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
            >
              <XAxis dataKey="date" stroke="#888" />
              <YAxis stroke="#888" />
              <Tooltip />
              <Bar dataKey="amount" fill="#4A3AFF" barSize={30} />
            </BarChart>
          </ResponsiveContainer> */}
          {/* <div className="mt-4"> */}
          <Bar data={chartData} options={chartOptions} />
          {/* </div> */}
        </div>
      </div>

      {/* Transactions */}
      <div className="p-6 mt-6 bg-white rounded-lg shadow-md">
        <div className="flex justify-between items-center">
          {/* Icon + Transactions Text */}
          <div className="flex gap-3 items-center">
            <div className="w-10 h-10 flex items-center justify-center rounded-full bg-[#595BD426]">
              <FiCreditCard className="text-lg text-gray-600" /> {/* Icon */}
            </div>
            <div className="flex flex-col">
              <h3 className="text-lg font-semibold">Transactions</h3>
              <span className="text-xs text-gray-500">
                View all transactions
              </span>
            </div>
          </div>

          {/* View Link */}
          {/* <a href="#" className="text-[#EFA005] hover:underline">
            View
          </a> */}
          <Link
            to={`${RoutePaths.USER_SAVINGS_TRANSACTION}`}
            className="text-[#EFA005] hover:underline"
          >
            View
          </Link>
        </div>
      </div>

      <SavingsTabs
        allSavings={allSavings}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
    </div>
  );
}

export default HowJomptVaultWorks;
