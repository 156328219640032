import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { modals } from 'components/shared/modal/GenericModal';

interface GenericModalState {
  title: string;
  subTitle: string;
  isOpen: boolean;
  props?: any;
  name: keyof typeof modals;
}

type OpenAction = {
  title?: string;
  subTitle?: string;
  name: GenericModalState['name'];
  props?: any;
};

const initialState: GenericModalState = {
  title: '',
  subTitle: '',
  isOpen: false,
  props: null,
  name: '',
};

export const genericModalSlice = createSlice({
  name: 'generic_modal',
  initialState,
  reducers: {
    open(state, action: PayloadAction<OpenAction>) {
      state.title = action.payload.title || '';
      state.subTitle = action.payload.subTitle || '';
      state.name = action.payload.name;
      state.props = action.payload.props;
      state.isOpen = true;
    },
    close(state: GenericModalState) {
      state.title = '';
      state.subTitle = '';
      state.name = '';
      state.isOpen = false;
      state.props = null;
    },
    setHeading(
      state,
      action: PayloadAction<{ title: string; subTitle: string }>
    ) {
      state.title = action.payload.title;
      state.subTitle = action.payload.subTitle;
    },
  },
});

export const { open, close, setHeading } = genericModalSlice.actions;

export default genericModalSlice.reducer;
