import React, { useState, useEffect } from 'react';
import SavingsSummary from './SavingsSummary';
import { appAxios } from 'api/axios';
import { useFormik } from 'formik';
import * as yup from 'yup';
import InputField from 'components/shared/input-field';
import SelectInput from 'components/shared/select-input';
import Button from 'components/shared/button';
import { formatToNaira } from 'functions/currencyConveter';
import { OptionType } from '../../../../types';
import { useNavigate } from 'react-router-dom';
import Checkbox from 'components/shared/checkbox/index';
import { getTokenDetails } from 'functions/userSession';
import { decodeToken } from 'react-jwt';
import { toast } from 'react-toastify';
import { selectSavingsName, selectInterestRate } from 'store/slices/savings';
import { useAppSelector } from 'store/hooks';
import CardDisclaimerModal from 'components/shared/modal/CardDisclaimerModal';

// Add PaystackPop to the window object type
declare global {
  interface Window {
    PaystackPop: any;
  }
}

interface FinishSavingsProps {
  frequency?: string;
  monthlyContribution?: string;
  targetAmount?: string;
  startDate?: string;
  endDate?: string;
  preferredTime?: string;
  autoSave?: boolean;
  autoWithdrawal?: boolean;
  status?: string;
  savingSource?: string;
  interestTagentSaving?: boolean;
  targetBreakBeforeEndDate?: boolean;
  savingsTime?: string;
  savingsType?: string;
  cardToken?: string;
  cardDetails?: {
    cardNumber: string;
    expiryMonth: string;
    expiry_year: string;
    cvv: string;
  };
}
interface FinishSavingsTargetProps {
  handleBack: () => void;
  handleNext: () => void;
  handleDataSubmit: () => void;
  formValues: FinishSavingsProps;
  setFormValues: (data: FinishSavingsProps) => void;
}

const validationSchema = yup.object({
  monthlyContribution: yup
    .string()
    .required('Preferred amount is required'),
  targetAmount: yup.string().required('Goal amount is required'),
  preferredTime: yup
    .string()
    .when('autoSave', {
      is: true,
      then: (schema) => schema.required('Preferred time is required')
        .matches(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/, 'Invalid time format'),
      otherwise: (schema) => schema.optional(),
    }),
  startDate: yup.string()
    .required('Start date is required'),
  endDate: yup
    .string()
    .required('End date is required'),
  autoSave: yup.boolean(),
  autoWithdrawal: yup.boolean(),
  savingSource: yup.string().required('Saving source is required'),
  savingsType: yup.string().optional(),
  interestTagentSaving: yup
    .boolean()
    .oneOf([true], 'You must agree to this term')
    .required('You must agree to this term'),
  targetBreakBeforeEndDate: yup
    .boolean()
    .oneOf([true], 'You must agree to this term')
    .required('You must agree to this term'),
});

function FinishSavingsTarget({
  handleBack,
  handleNext,
  handleDataSubmit,
  formValues,
  setFormValues,
}: FinishSavingsTargetProps) {
  const [dropdownOptions, setDropdownOptions] = useState('');
  const navigate = useNavigate();
  const [sourceOptions, setSourceOptions] = useState<OptionType[]>([]);
  const [isAuthorizeDebitChecked, setIsAuthorizeDebitChecked] = useState(formValues?.targetBreakBeforeEndDate || false);
  const [isInterestTargetChecked, setIsInterestTargetChecked] = useState(formValues?.interestTagentSaving || false);
  const savingsType = useAppSelector((state) => state.savings.savingsType);
  const interestRate = useAppSelector(selectInterestRate);
  const [showCardDisclaimer, setShowCardDisclaimer] = useState(false);

  // Fix date formatting function to return plain YYYY-MM-DD without time component
  const formatDateForAPI = (dateInput: string | number | Date | undefined): string => {
    if (!dateInput) return '';
    
    try {
      let date;
      
      // Handle various input types
      if (typeof dateInput === 'number') {
        date = new Date(dateInput);
      } else if (dateInput instanceof Date) {
        date = dateInput;
      } else {
        date = new Date(dateInput);
      }
      
      // Check if date is valid
      if (isNaN(date.getTime())) {
        console.error('Invalid date input:', dateInput);
        return '';
      }
      
      // Format as YYYY-MM-DD without time component
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      
      return `${year}-${month}-${day}`;
    } catch (error) {
      console.error('Error formatting date:', error, 'Input was:', dateInput);
      return '';
    }
  };

  const formik = useFormik({
    initialValues: {
      ...formValues,
      // Format dates to ensure they're strings in YYYY-MM-DD format
      startDate: formatDateForAPI(formValues?.startDate),
      endDate: formatDateForAPI(formValues?.endDate),
      savingsType: savingsType,
      targetBreakBeforeEndDate: formValues?.targetBreakBeforeEndDate || false,
      interestTagentSaving: formValues?.interestTagentSaving || false,
    },
    validationSchema,
    onSubmit: (values) => {
      // Format the dates to YYYY-MM-DD without time component
      const formattedValues = {
        ...values,
        startDate: formatDateForAPI(values.startDate),
        endDate: formatDateForAPI(values.endDate),
        savingsTime: values.preferredTime,
        savingsType: 'jompVault',
      };
      
      console.log('Data being submitted with formatted dates:', formattedValues);
      setFormValues(formattedValues);
    },
  });

  useEffect(() => {
    console.log('Updated form values:', formik.values);
    console.log(savingsType);
  }, [formik.values]);

  // Update the checkbox states if formik values change
  useEffect(() => {
    setIsAuthorizeDebitChecked(!!formik.values.targetBreakBeforeEndDate);
    setIsInterestTargetChecked(!!formik.values.interestTagentSaving);
  }, [formik.values.targetBreakBeforeEndDate, formik.values.interestTagentSaving]);

  const saveOptions: OptionType[] = [
    {
      label: 'Daily',
      value: 'daily',
    },
    {
      label: 'Weekly',
      value: 'weekly',
    },
    {
      label: 'Monthly',
      value: 'monthly',
    },
  ];

  useEffect(() => {
    const getPaymentSource = async () => {
      try {
        const { data } = await appAxios.get(`/get-saving-source`);
        if (data && data.statusCode === 200) {
          const formattedOptions = data.data.map((source: any) => ({
            label: source.name,
            value: source.id,
          }));
          setSourceOptions(formattedOptions);
        }
      } catch (error) {}
    };

    getPaymentSource();
  }, []);

  const formatWithCommas = (value: string) => {
    const sanitizedValue = value.replace(/[^0-9]/g, '');
    return sanitizedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index?: number
  ) => {
    const { name, value } = e.target;

    const plainValue = value.replace(/,/g, '');
    if (name === `monthlyContribution` && index !== undefined) {
      const formattedValue = formatWithCommas(plainValue);
      formik.setFieldValue(`monthlyContribution`, formattedValue);
      formik.handleChange(e);
    } else if (name === `targetAmount` && index !== undefined) {
      const formattedValue = formatWithCommas(plainValue);
      formik.setFieldValue(`targetAmount`, formattedValue);
      formik.handleChange(e);
    } else {
      formik.handleChange(e);
    }

    // clearErrors(name);
  };

  const getContributionLabel = () => {
    switch (formik.values.frequency) {
      case 'daily':
        return 'Preferred amount to save on a daily basis';
      case 'weekly':
        return 'Preferred amount to save on a weekly basis';
      case 'monthly':
        return 'Preferred amount to save on a monthly basis';
      default:
        return 'Preferred amount to save';
    }
  };

  useEffect(() => {
    if (
      formik.values.startDate &&
      formik.values.frequency &&
      formik.values.targetAmount &&
      formik.values.monthlyContribution
    ) {
      const startDate = new Date(formik.values.startDate);
      const targetAmount = parseFloat(
        formik.values.targetAmount.replace(/,/g, '')
      );
      const contribution = parseFloat(
        formik.values.monthlyContribution.replace(/,/g, '')
      );

      let endDate = new Date(startDate);

      if (formik.values.frequency === 'daily') {
        const daysRequired = Math.ceil(targetAmount / contribution);
        endDate.setDate(startDate.getDate() + daysRequired);
      } else if (formik.values.frequency === 'weekly') {
        const weeksRequired = Math.ceil(targetAmount / contribution);
        endDate.setDate(startDate.getDate() + weeksRequired * 7);
      } else if (formik.values.frequency === 'monthly') {
        const monthsRequired = Math.ceil(targetAmount / contribution);
        endDate.setMonth(startDate.getMonth() + monthsRequired);
      }

      formik.setFieldValue('endDate', endDate.toISOString().split('T')[0]);
    }
  }, [
    formik.values.startDate,
    formik.values.frequency,
    formik.values.targetAmount,
    formik.values.monthlyContribution,
  ]);

  useEffect(() => {
    // Ensure we have default values for boolean fields and properly formatted dates
    const updatedValues = {
      ...formik.values,
      startDate: formatDateForAPI(formik.values.startDate),
      endDate: formatDateForAPI(formik.values.endDate),
      autoSave: formik.values.autoSave !== undefined ? formik.values.autoSave : false,
      autoWithdrawal: formik.values.autoWithdrawal !== undefined ? formik.values.autoWithdrawal : false,
      savingsType: savingsType || 'jompVault',
    };
    
    // If autoSave is false, we don't need preferredTime
    if (updatedValues.autoSave === false) {
      formik.setFieldValue('preferredTime', '');
    }
    
    setFormValues(updatedValues);
  }, [formik.values, savingsType]);

  const targetAmount = parseFloat(
    formValues?.targetAmount?.toString().replace(/,/g, '') || '0'
  );

  const verifyTransaction = async (reference: string) => {
    try {
      const response = await fetch(
        `https://api.paystack.co/transaction/verify/${reference}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer sk_test_74e8cd507841f3bf88cdbcfcc5161a22186f0f17`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        throw new Error('Transaction verification failed');
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error verifying transaction:', error);
      throw error;
    }
  };

  const initializePaystack = () => {
    const token = getTokenDetails();
    const decodedToken = decodeToken<any>(token!);
    const emailAddress =
      decodedToken[
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'
      ];

    const handler = window.PaystackPop.setup({
      key: 'pk_test_dcf001888005335ea262e8ec9491f490d11731b6',
      email: emailAddress,
      amount: parseFloat((formik.values.monthlyContribution || '0').replace(/,/g, '')) * 100,
      currency: 'NGN',
      //
      channels: ['card'],
      ref: 'TXN' + Math.floor(Math.random() * 1000000000 + 1),
      callback: function (response: any) {
        const reference = response.reference;
        console.log(`Payment complete! Reference: ${reference}`);

        // Make an API call to verify the transaction
        verifyTransaction(reference)
          .then((verificationData) => {
            console.log('Transaction verified:', verificationData);

            if (verificationData.data.status === 'success') {
              const debitCardDetails = {
                token: verificationData.data.authorization.authorization_code,
                signature: verificationData.data.authorization.signature,
                cardType: verificationData.data.authorization.card_type,
                firstSixDigits: verificationData.data.authorization.bin,
                lastFourDigits: verificationData.data.authorization.last4,
                expMonth: verificationData.data.authorization.exp_month,
                expYear: verificationData.data.authorization.exp_year,
                channel: verificationData.data.authorization.channel,
              };

              // Add card token to form values
              const updatedValues = {
                ...formik.values,
                cardToken: debitCardDetails.token,
                // Ensure dates are in ISO format
                startDate: new Date(formik.values.startDate).toISOString(),
                endDate: new Date(formik.values.endDate).toISOString()
              };
              
              // Update formik state with card token
              formik.setFieldValue('cardToken', debitCardDetails.token);
              
              // Log submission data
              console.log('Submitting with card token (ISO dates):', updatedValues);
              
              // Update parent state with the values including card token
              setFormValues(updatedValues);
              
              // Submit data after state update
              setTimeout(() => {
                handleDataSubmit();
              }, 0);
            }
          })
          .catch((error) => {
            console.error('Error during verification:', error);
            toast.error('Payment verification failed. Please try again.');
          });
      },
      onClose: function () {
        console.log('Payment window closed');
      },
    });

    handler.openIframe();
  };

  const handleSourceSelection = (name: string, option: OptionType | null) => {
    if (option) {
      formik.setFieldValue(name, option.label);
      
      // If Card is selected as payment source, show the disclaimer
      if (option.label === 'Card') {
        setShowCardDisclaimer(true);
      }
    }
  };

  // Handle proceeding with card payment after disclaimer acknowledgment
  const handleProceedWithCard = () => {
    setShowCardDisclaimer(false);
    
    // Check if the required Paystack script is loaded
    if (typeof window.PaystackPop === 'undefined') {
      const script = document.createElement('script');
      script.src = 'https://js.paystack.co/v1/inline.js';
      script.async = true;
      script.onload = () => {
        initializePaystack();
      };
      document.body.appendChild(script);
    } else {
      initializePaystack();
    }
  };

  const validateAndFormatDates = () => {
    // Get current date with time set to midnight
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    // Check if startDate is valid and not in the past
    if (formik.values.startDate) {
      try {
        let startDate: Date;
        
        // Handle various input formats
        if (typeof formik.values.startDate === 'number') {
          startDate = new Date(formik.values.startDate);
        } else {
          startDate = new Date(formik.values.startDate);
        }
        
        // Check if date is valid
        if (isNaN(startDate.getTime())) {
          console.error('Invalid start date:', formik.values.startDate);
          
          // Use today's date if the current value is invalid
          const formattedToday = formatDateForAPI(today);
          formik.setFieldValue('startDate', formattedToday);
          toast.warning('Invalid start date detected. Using today\'s date instead.');
          return false;
        }
        
        startDate.setHours(0, 0, 0, 0);
        
        // If startDate is in the past, set it to today
        if (startDate < today) {
          const formattedDate = formatDateForAPI(today);
          formik.setFieldValue('startDate', formattedDate);
          toast.info('Start date has been set to today as past dates are not allowed');
          
          // Also update endDate if needed
          if (formik.values.endDate) {
            let endDate: Date;
            
            try {
              if (typeof formik.values.endDate === 'number') {
                endDate = new Date(formik.values.endDate);
              } else {
                endDate = new Date(formik.values.endDate);
              }
              
              if (isNaN(endDate.getTime()) || endDate <= today) {
                // Set end date to a reasonable future date (e.g., 1 month from today)
                const newEndDate = new Date(today);
                newEndDate.setMonth(today.getMonth() + 1);
                formik.setFieldValue('endDate', formatDateForAPI(newEndDate));
              }
            } catch (error) {
              console.error('Error validating end date:', error);
              // Set a default end date
              const newEndDate = new Date(today);
              newEndDate.setMonth(today.getMonth() + 1);
              formik.setFieldValue('endDate', formatDateForAPI(newEndDate));
            }
          }
          
          return false; // Dates needed correction
        }
        
        // Even if the date is valid, ensure it's in the correct format
        const formattedStartDate = formatDateForAPI(formik.values.startDate);
        if (formattedStartDate !== formik.values.startDate) {
          formik.setFieldValue('startDate', formattedStartDate);
        }
        
        // Do the same for end date
        if (formik.values.endDate) {
          const formattedEndDate = formatDateForAPI(formik.values.endDate);
          if (formattedEndDate !== formik.values.endDate) {
            formik.setFieldValue('endDate', formattedEndDate);
          }
        }
      } catch (error) {
        console.error('Error validating dates:', error);
        // Use today as fallback for start date
        formik.setFieldValue('startDate', formatDateForAPI(today));
        return false;
      }
    } else {
      // If no start date is set, use today
      formik.setFieldValue('startDate', formatDateForAPI(today));
      toast.info('Start date has been set to today');
      return false;
    }
    
    return true; // Dates are valid
  };

  useEffect(() => {
    // Validate the start date when component mounts
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    if (formik.values.startDate) {
      const startDate = new Date(formik.values.startDate);
      startDate.setHours(0, 0, 0, 0);
      
      // If start date is in the past, update it to today
      if (startDate < today) {
        const formattedDate = today.toISOString().split('T')[0];
        formik.setFieldValue('startDate', formattedDate);
        
        // Also update end date if needed
        if (formik.values.endDate) {
          const endDate = new Date(formik.values.endDate);
          if (endDate <= today) {
            // Set end date to a reasonable future date (e.g., 1 month from today)
            const newEndDate = new Date(today);
            newEndDate.setMonth(today.getMonth() + 1);
            formik.setFieldValue('endDate', newEndDate.toISOString().split('T')[0]);
          }
        }
      }
    }
  }, []);

  // Add this useEffect after the formik initialization
  useEffect(() => {
    // Format dates immediately when component loads
    if (formik.values.startDate) {
      const formattedStartDate = formatDateForAPI(formik.values.startDate);
      if (formattedStartDate && formattedStartDate !== formik.values.startDate) {
        formik.setFieldValue('startDate', formattedStartDate);
      }
    }
    
    if (formik.values.endDate) {
      const formattedEndDate = formatDateForAPI(formik.values.endDate);
      if (formattedEndDate && formattedEndDate !== formik.values.endDate) {
        formik.setFieldValue('endDate', formattedEndDate);
      }
    }
    
    // Log the formatted values for debugging
    console.log('Initial formatted dates:', {
      startDate: formik.values.startDate,
      endDate: formik.values.endDate
    });
  }, []);

  // Add a function to validate and prepare data for submission
  const prepareAndValidateSubmission = () => {
    // Validate dates first
    const startDate = formatDateForAPI(formik.values.startDate);
    const endDate = formatDateForAPI(formik.values.endDate);
    
    // Update formik values with properly formatted dates
    formik.setFieldValue('startDate', startDate);
    formik.setFieldValue('endDate', endDate);
    
    // Verify dates are valid and in the future
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    const formattedStartDate = new Date(startDate);
    formattedStartDate.setHours(0, 0, 0, 0);
    
    if (formattedStartDate < today) {
      toast.error('Start date must be today or in the future');
      return false;
    }
    
    // Ensure both dates are valid
    if (!startDate || !endDate) {
      toast.error('Both start and end dates are required');
      return false;
    }
    
    return true;
  };

  // Modify the handleNextWithFormattedDates function to use direct ISO formatting
  const handleNextWithFormattedDates = () => {
    // Create a final data object directly with ISO date strings
    const finalValues = {
      ...formik.values,
      // Keep dates in their original ISO format from formik or convert to ISO if needed
      startDate: new Date(formik.values.startDate).toISOString(),
      endDate: new Date(formik.values.endDate).toISOString(),
      savingsTime: formik.values.preferredTime,
      savingsType: 'jompVault',
    };
    
    // Log the final values being submitted for debugging
    console.log('Final values for API submission (raw ISO):', finalValues);
    
    // Update parent state with the ISO-formatted values
    setFormValues(finalValues);
    
    // Call the original handleDataSubmit
    setTimeout(() => {
      if (typeof handleDataSubmit === 'function') {
        handleDataSubmit();
      } else {
        handleNext();
      }
    }, 0);
  };

  // Let's also add a logging mechanism before the API call
  useEffect(() => {
    // Log whenever formValues change - this is what gets sent to API
    console.log('Current formValues (sent to API):', formValues);
  }, [formValues]);

  return (
    <div className="flex flex-col gap-[30px] w-full text-BrandGray900">
      <div className="flex flex-col gap-y-[10px] mb-[30px]">
        <div className="flex flex-col justify-between items-center lg:flex-row">
          <div className="mb-4 lg:mb-0">
            <h1 className="font-semibold">
              Create a JompReserve Target Savings.
            </h1>
            <p>
              Set up a new savings target and get paid everyday (@ {interestRate || 12}% interest
              P.A) to reach your goals faster.
            </p>
          </div>
        </div>
      </div>
      {/* ********************************** */}
      <div className="flex flex-col-reverse gap-y-[20px] lg:flex-row lg:gap-x-[50px]">
        <main className=" flex-1 bg-white rounded-md px-[15px] py-[20px] lg:w-[60%]">
          <header className="flex flex-col gap-y-[3px] mb-[20px]">
            <h1 className="font-semibold text-[20px]">Finish Setting Up</h1>
            <p>Finalize your target settings</p>
            <div className="h-[1px] bg-gray-3 mt-[10px] mb-[25px]"></div>
          </header>
          <form action="" className="flex flex-col gap-y-[20px]">
            <div className="flex flex-col gap-y-[2px]">
              <label htmlFor="autoSave">
                Do you want to enable auto savings?
              </label>

              <div className="flex gap-3">
                <div
                  className={`flex items-center cursor-pointer w-[40px] h-[20px] rounded-full transition-all 
                  ${formik.values.autoSave ? 'bg-[#EFA005]' : 'bg-gray-300'}`}
                  onClick={() =>
                    formik.setFieldValue('autoSave', !formik.values.autoSave)
                  }
                >
                  <div
                    className={`w-[25px] h-[25px] bg-white rounded-full shadow-md transform transition-all 
                    ${formik.values.autoSave ? 'translate-x-[15px]' : 'translate-x-0'}`}
                  />
                </div>
                <p className="mt-1 text-sm">
                  {formik.values.autoSave ? 'Yes' : 'No'}
                </p>
              </div>
            </div>

            <div>
              {formik.values.autoSave && (
                <InputField
                  label="Preferred Time"
                  type="time"
                  name="preferredTime"
                  placeholder="e.g 4:30 am"
                  value={formik.values.preferredTime || ''}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.errors.preferredTime}
                />
              )}
            </div>

            <div className="flex-col w-full">
              <div className="flex gap-5 w-full">
                <div className="w-full">
                  <SelectInput
                    label="Saving Source"
                    name="savingSource"
                    placeholder="Select saving source"
                    onChange={handleSourceSelection}
                    options={sourceOptions}
                    value={sourceOptions.find(
                      (option) => option.label === formik.values.savingSource
                    ) || null}
                    required
                    error={formik.errors.savingSource}
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-y-[2px]">
              <label htmlFor="autoSave">
                Do you want to enable auto withdrawal at the end of your savings
                duration?
              </label>

              <div className="flex gap-3">
                <div
                  className={`flex items-center cursor-pointer w-[40px] h-[20px] rounded-full transition-all 
                  ${formik.values.autoWithdrawal ? 'bg-[#EFA005]' : 'bg-gray-300'}`}
                  onClick={() =>
                    formik.setFieldValue(
                      'autoWithdrawal',
                      !formik.values.autoWithdrawal
                    )
                  }
                >
                  <div
                    className={`w-[25px] h-[25px] bg-white rounded-full shadow-md transform transition-all 
                    ${formik.values.autoWithdrawal ? 'translate-x-[15px]' : 'translate-x-0'}`}
                  />
                </div>
                <p className="mt-1 text-sm">
                  {formik.values.autoWithdrawal ? 'Yes' : 'No'}
                </p>
              </div>
            </div>

            {/* <hr /> */}
            {/* Agreements */}
            <div className="flex flex-col space-y-2">
              <Checkbox
                id="interestTagentSaving"
                label={`I hereby agree that I will forfeit the interest accrued on this target savings (@ ${interestRate || 12}% interest P.A) if I fail to meet this target amount of (${formatToNaira(targetAmount) || 0}) by the end of the savings duration.`}
                checked={isInterestTargetChecked}
                onChange={(e) => {
                  const checked = (e.target as HTMLInputElement).checked;
                  setIsInterestTargetChecked(checked);
                  formik.setFieldValue('interestTagentSaving', checked);
                }}
                className="mb-4 text-sm"
              />
              {formik.touched.interestTagentSaving && formik.errors.interestTagentSaving && (
                <p className="text-xs text-red-500">{formik.errors.interestTagentSaving as string}</p>
              )}
              <Checkbox
                id="targetBreakBeforeEndDate"
                label={`I hereby agree to this: "If I break this target before the end of the savings duration, I will lose all the interest accrued (@ ${interestRate || 12}% interest P.A)."`}
                checked={isAuthorizeDebitChecked}
                onChange={(e) => {
                  const checked = (e.target as HTMLInputElement).checked;
                  setIsAuthorizeDebitChecked(checked);
                  formik.setFieldValue('targetBreakBeforeEndDate', checked);
                }}
                className="mb-4 text-sm"
              />
              {formik.touched.targetBreakBeforeEndDate && formik.errors.targetBreakBeforeEndDate && (
                <p className="text-xs text-red-500">{formik.errors.targetBreakBeforeEndDate as string}</p>
              )}
            </div>

            <div className="h-[1px] bg-gray-3 mt-[10px] mb-[25px]"></div>

            <div className="flex justify-end gap-x-[20px]">
              <button
                onClick={handleBack}
                className="border border-[#EFA005] px-12 py-2 rounded-[8px] outline-none focus:shadow-md text-[#EFA005]"
              >
                Back
              </button>
              <Button
                type="button"
                className="outline-none"
                onClick={(e: React.FormEvent) => {
                  e.preventDefault();
                  
                  // Prepare form values with ISO dates
                  const finalValues = {
                    ...formik.values,
                    // Ensure dates are in ISO format
                    startDate: new Date(formik.values.startDate).toISOString(),
                    endDate: new Date(formik.values.endDate).toISOString(),
                    savingsTime: formik.values.preferredTime,
                    savingsType: 'jompVault',
                    interestTagentSaving: true,
                    targetBreakBeforeEndDate: true
                  };
                  
                  // Log final values for debugging
                  console.log('Submitting with ISO dates:', finalValues);
                  
                  // Update parent state with ISO dates
                  setFormValues(finalValues);
                  
                  // Call the submit handler
                  setTimeout(() => {
                    handleDataSubmit();
                  }, 0);
                }}
              >
                Create Savings
              </Button>
            </div>
          </form>
        </main>

        <SavingsSummary formValues={formValues} />
      </div>

      {/* Add the CardDisclaimerModal */}
      <CardDisclaimerModal
        isOpen={showCardDisclaimer}
        onClose={() => setShowCardDisclaimer(false)}
        onProceed={handleProceedWithCard}
      />
    </div>
  );
}

export default FinishSavingsTarget;
