import React, { useState } from 'react';
import BeginSavingsTarget from '../../../pages/user/dashboard/savings/BeginSavingsTarget';
import FinishSavingsTarget from '../../../pages/user/dashboard/savings/FinishSavingsTarget';
import { SavingsFormData } from '../../../types';
import { useNavigate } from 'react-router-dom';
import { appAxios } from 'api/axios';
import { ArrowLeft2 } from 'iconsax-react';
import { getTokenDetails } from 'functions/userSession';
import { sendCatchFeedback, sendFeedback } from 'functions/feedback';
import { decodeToken } from 'react-jwt';
import { TokenType } from 'types/jwt';
import AuthAnimatedModal from 'components/shared/modal/AuthAnimatedModal';

const SavingsForms: React.FC = () => {
  const token = getTokenDetails();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const decodedToken = decodeToken<TokenType>(token!);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const customerId = decodedToken?.customerId;

  const [formValues, setFormValues] = useState<SavingsFormData>({
    customerId: customerId,
    goalName: '',
    targetAmount: '',
    monthlyContribution: '',
    startDate: '',
    endDate: '',
    preferredTime: '',
    autoSave: true,
    autoWithdrawal: true,
    status: 'pending',
    savingSource: '',
    savingCategory: '',
    interestTagentSaving: true,
    targetBreakBeforeEndDate: true,
    savingsTime: '12:10',
    frequency: '',
    cardDetails: {
      cardNumber: '',
      expiryMonth: '',
      expiry_year: '',
      cvv: '',
    },
  });

  const handleNext = () => {
    if (currentStep < 2) {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => prev - 1);
    }
  };

  const handleFormData = (data: Partial<SavingsFormData>) => {
    setFormValues((prevData) => ({ ...prevData, ...data }));
  };

  const formatToISO8601 = (date: string) => {
    try {
      // Parse the date
      const parsedDate = new Date(date);
      
      // Check if date is valid
      if (isNaN(parsedDate.getTime())) {
        console.error('Invalid date format:', date);
        return '';
      }
      
      // Return the date in ISO format with time component zeroed out
      // Format: YYYY-MM-DDT00:00:00.000Z
      return parsedDate.toISOString();
    } catch (error) {
      console.error('Error formatting date:', error);
      return '';
    }
  };

  const handleProceed = () => {
    setIsModalOpen(false);
    navigate(-1);
  };

  const handleDataSubmit = async () => {
    try {
      setLoading(true);

      const targetAmountWithoutCommas = String(formValues.targetAmount).replace(
        /,/g,
        ''
      );
      const monthlyContributionWithoutCommas = String(
        formValues.monthlyContribution
      ).replace(/,/g, '');
      const formattedFormValues = {
        ...formValues,
        startDate: formatToISO8601(formValues?.startDate),
        endDate: formatToISO8601(formValues?.endDate),
        savingsTime: formValues?.preferredTime,
        targetAmount: targetAmountWithoutCommas,
        monthlyContribution: monthlyContributionWithoutCommas,
      };

      const { data } = await appAxios.post(
        `/create-savings-goal`,
        formattedFormValues
      );
      console.log(data, 'data');
      if (data && data.statusCode === 201) {
        // sendFeedback(data?.message, 'success');
        setLoading(false);
        setIsModalOpen(true);
      } else {
        setLoading(false);
        sendCatchFeedback(data);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <div className="mt-4">
      <button
        onClick={() => navigate(-1)}
        className="flex gap-x-2 text-[#EFA005]"
      >
        <ArrowLeft2 /> Back
      </button>
      <div>
        {currentStep === 0 && (
          <BeginSavingsTarget
            handleNext={handleNext}
            setFormValues={handleFormData}
            formValues={formValues}
          />
        )}
        {currentStep === 1 && (
          <FinishSavingsTarget
            handleBack={handleBack}
            handleNext={handleDataSubmit}
            formValues={formValues}
            setFormValues={handleFormData}
            handleDataSubmit={handleDataSubmit}
          />
        )}
      </div>
      <AuthAnimatedModal
        isOpen={isModalOpen}
        onClose={handleProceed}
        onConfirm={handleProceed}
        title="Savings Goal Submitted!"
        btnText="Proceed"
        content={<p>You have successfully created a savings goal.</p>}
      />
    </div>
  );
};

export default SavingsForms;
