import React from 'react';
import ReactModal from 'react-modal';
import Button from '../button';
import styles from './style.module.css';
import { FaCheckCircle } from 'react-icons/fa';
import { motion, useMotionValue } from 'framer-motion';
import CircularProgress from '../animations/check';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  btnText: string;
  content: React.ReactNode;
  onConfirm?: () => void;
};

const AuthAnimatedModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  title,
  content,
  btnText,
  onConfirm,
  ...rest
}) => {
  const progress = useMotionValue(90);
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center w-full h-full overflow-y-auto bg-gray-500 bg-opacity-50 px-5">
      <div className="relative p-4 w-full max-w-md bg-white rounded-lg shadow-lg">
        <button
          type="button"
          className="absolute top-3 right-3 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 flex justify-center items-center"
          onClick={onClose}
        >
          <svg
            className="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
          <span className="sr-only">Close modal</span>
        </button>
        <div className="p-4 text-center">
          <div className="flex items-center justify-center my-4">
            <motion.div
              initial={{ x: 0 }}
              animate={{ x: 100 }}
              style={{ x: progress }}
              transition={{ duration: 1 }}
            />
            <CircularProgress progress={progress} />
          </div>
          <h3 className="mb-5 text-lg font-bold ">{title}</h3>
          <div className="mb-4">{content}</div>
          <div className="flex justify-center gap-4">
            <Button type="button" onClick={onConfirm} className="mt-6 w-full">
              {btnText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthAnimatedModal;
