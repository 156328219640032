import React from 'react';
import { X } from 'lucide-react';
import Button from '../button';
import { formatToNaira } from 'functions/currencyConveter';

interface DetailItem {
  label: string;
  value: string;
  isPercentage?: boolean;
  isInterest?: boolean;
  isSuccess?: boolean;
}

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  amount: string;
  status: string;
  details: DetailItem[];
  primaryAction: { text: string; onClick: () => void };
  secondaryAction?: { text: string; onClick: () => void };
}

const SavingsTabModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  title,
  amount,
  status,
  details,
  primaryAction,
  secondaryAction,
}) => {
  if (!isOpen) return null;
  const isRolledOver = status === 'RolledOver';

  const modalDetails: DetailItem[] = [
    { label: 'Savings Title', value: title },
    { label: 'Savings Goal', value: amount },
    ...details,
    { 
      label: 'Goal Level', 
      value: '100%',
      isSuccess: true
    },
    { 
      label: 'Interest Earned', 
      value: formatToNaira(4047.23),
      isSuccess: true
    },
    {
      label: 'Rolled Over Interest',
      value: formatToNaira(40.47),
      isSuccess: true
    }
  ];

  return (
    <div className="flex overflow-y-auto fixed inset-0 z-50 justify-center items-center px-5 w-full h-full bg-gray-500 bg-opacity-50">
      <div className="relative p-6 w-full max-w-md bg-white rounded-lg shadow-lg">
        <button
          type="button"
          className="absolute top-3 right-3 text-gray-400 hover:text-gray-900"
          onClick={onClose}
        >
          <X size={20} />
        </button>

        <h2 className="text-lg font-semibold text-gray-800">
          {isRolledOver ? 'Rolled-over Savings' : 'Ongoing Savings'}
        </h2>
        <div className="p-4 mt-4 text-center bg-gray-50 rounded-md">
          <p className="text-3xl font-bold text-gray-900">{amount}</p>
          <span className={`inline-block px-3 py-1 mt-2 text-sm rounded-lg ${
            isRolledOver ? 'text-green-600 bg-green-100' : 'text-red-600 bg-red-100'
          }`}>
            {isRolledOver ? 'Successful' : status}
          </span>
        </div>

        <div className="mt-6 space-y-3">
          {modalDetails.map((item, index) => (
            <div key={`${item.label}-${index}`} className="flex justify-between items-center text-gray-700">
              <span>{item.label}</span>
              <span className={`font-medium ${
                item.isSuccess ? 'text-green-600' : 
                item.isPercentage ? 'text-red-600' : ''
              }`}>
                {item.value}
              </span>
            </div>
          ))}
        </div>

        <div className="grid grid-cols-2 gap-4 mt-6">
          <Button
            onClick={secondaryAction?.onClick}
            className="w-full text-yellow-500 bg-white border border-yellow-500 hover:bg-yellow-50"
          >
            {isRolledOver ? 'Withdraw' : 'Cancel'}
          </Button>
          <Button
            onClick={primaryAction.onClick}
            className="w-full text-white bg-yellow-500 hover:bg-yellow-600"
          >
            {isRolledOver ? 'Reinvest' : 'Withdraw'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SavingsTabModal;
