import { signOut, updateToken } from '../store/slices/user';
import { useAppDispatch } from '../store/hooks';
import { store } from '../store/store';
import { useAppSelector } from '../store/hooks';
import { API_URL } from './../functions/environmentVariables';
import { getTokenDetails } from './../functions/userSession';
import axios, { AxiosResponse } from 'axios';


export const appAxios = axios.create({
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  baseURL: API_URL,
  validateStatus: (status) => status <= 500,
});

let isRefreshing = false;
let refreshSubscribers: ((token: string) => void)[] = [];

appAxios.interceptors.request.use(
  (config) => {
    const sessionToken = getTokenDetails();
    const appState = store.getState();
    const storeToken = appState?.user.token;
    // const token = storeToken;

    const token = sessionToken;

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (err) => Promise.reject(err)
);

appAxios.interceptors.response.use(
  (res: AxiosResponse) => res,
  async (err) => {
    const originalConfig = err.config;
    const sessionToken = getTokenDetails();

    console.log(err);
    if (err.code === 'ERR_NETWORK' && !originalConfig._retry) {
      console.log(err);
      originalConfig._retry = true;

      // Reload window so user is redirected to login
      window.location.reload();
      store.dispatch(signOut());

      return appAxios(originalConfig);

      return Promise.reject(err);
    }
  }
);
