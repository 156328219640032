import { ReactNode } from "react";

const SectionLayout: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <main className="w-full lg:max-w-[1215px] lg:min-w-[1050px] mx-auto px-[20px] lg:px-0">
      {children}
    </main>
  );
};

export default SectionLayout;
