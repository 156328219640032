import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

// Define the state type
interface SavingsState {
  savingsType: string;
  interestRate: number;
}

// Define the initial state
const initialState: SavingsState = {
  savingsType: 'jompVault',
  interestRate: 0,
};

// Create the slice
export const savingsSlice = createSlice({ 
  name: 'savings',
  initialState,
  reducers: {
    setSavingsType: (state, action: PayloadAction<string>) => {
      state.savingsType = action.payload;
    },
    setInterestRate: (state, action: PayloadAction<number>) => {
      state.interestRate = action.payload;
    },
  },
});

export const { setSavingsType, setInterestRate   } = savingsSlice.actions;

export const selectSavingsName = (state: RootState) => state.savings.savingsType;

export const selectInterestRate = (state: RootState) => state.savings.interestRate;

export default savingsSlice.reducer; 