export const RoutePaths = Object.freeze({
  // Home
  HOMEPAGE: '/',
  SCHOOLFEES_GETSTARTED: '/school-fees',
  RENT_GETSTARTED: '/rent',
  TRANSPORT_GETSTARTED: '/transportation',
  TERMS_CONDITIONS: '/terms&conditions',
  PRIVACY: '/privacy',
  GETSTARTED: '/getstarted',
  LOGIN: '/login',
  SCHOOL_FEES: '/school-fees/for-my-child',
  INSTITUTION_SCHOOLFEES: '/school-fees/for-myself',
  RENT: '/rent/start-rent',
  TRANSPORT: '/transportation/start-transport',
  SERVICE_DETAILS: '/service/:id',
  PUBLIC_PAYMENT: 'pay-now/:id',
  // User
  // USER_LOGIN: '/user/login',
  USER_REGISTER: '/user/register',
  USER_EMAIL_VERIFICATION: '/user/otp-verify',
  USER_COMPLIANCE_NIGERIA: '/user/compliance/ng',
  USER_COMPLIANCE_USA: '/user/compliance/us',
  USER_DASHBOARD: '/user/dashboard',
  USER_SERVICES: '/user/dashboard/services',
  USER_LOAN_REPAYMENT: '/user/dashboard/loans',
  USER_VIEW_LOAN_REPAYMENT: '/user/dashboard/loans/:id',
  USER_ACCEPT_SERVICE: '/accept-loan',
  USER_PAYSTACK_CONFIRM: '/paystack/callback',
  USER_REJECT_SERVICE: '/reject-loan',
  CUSTOMER_SERVICES: '/user/dashboard/services/customers',
  CUSTOMER_SERVICES_DETAILS: '/user/dashboard/services/customers/:id',
  USER_SERVICE_DETAILS: '/user/dashboard/services/:id',
  USER_SERVICE_PAYMENT: '/user/dashboard/services/pay-now/:id',
  USER_SETTINGS: '/user/dashboard/settings',
  USER_PROFILE: '/user/dashboard/settings/profile',
  USER_PASSWORD_RESET: '/user/dashboard/settings/password-reset',
  USER_PRIVACY: '/user/dashboard/settings/privacy-policy',
  USER_SUPPORT: '/user/dashboard/support',
  USER_BOOKINGS: '/user/dashboard/bookings',
  USER_TRANSACTIONS: '/user/dashboard/transactions',
  USER_SCHOOL_FEES: '/user/dashboard/school-fees',
  USER_TRANSPORTATION: '/user/dashboard/transportation',
  USER_BANK_WITHDRAWAL: '/user/dashboard/withdrawal',

  USER_INSTUTION_SCHOOL_FEES: '/user/dashboard/institution-school-fees',
  // USER_LEGAL: '/user/dashboard/legal',
  USER_COOKIES_POLICY: '/user/dashboard/legal/cookies-policy',
  USER_TERMS: '/user/dashboard/legal/terms',
  USER_PRIVACY_POLICY: '/user/dashboard/legal/policy',
  CHANGE_PASSWORD: '/change-password',
  USER_PUBLIC_ONE_TIME_PAYMENT: '/service/:id/pay',
  USER_WALLET: '/user/dashboard/wallet',
  USER_SAVINGS: '/user/dashboard/savings',
  USER_SAVINGS_JOMPVAULT: '/user/dashboard/savings/jompvault',
  USER_CREATE_SAVINGS_JOMPVAULT:
    '/user/dashboard/savings/jompvault/create-savings',
  USER_SAVINGS_TRANSACTION: '/user/dashboard/savings/jompvault/transaction',
  USER_SAVINGS_JOMPVAULT_DETAILS: '/user/dashboard/savings/jompvault/:id',
  USER_SAVINGS_DETAILS: '/user/dashboard/savings/:id',
  USER_LOAN_REPAYMENTS: '/user/dashboard/loan-repayment',
  USER_LOAN_REPAYMENT_DETAILS: '/user/dashboard/loan-repayment/:id',
  CREATE_USER_SAVINGS: '/user/dashboard/savings/create-savings',

  // Vendor
  // VENDOR_LOGIN: '/vendor/login',
  PROVIDER_REGISTER: '/provider/register',
  PROVIDER_FORGOT_PASSWORD: '/provider/forgot-password',
  PROVIDER_RESET_PASSWORD: '/reset-password',
  PROVIDER_EMAIL_VERIFICATION: '/provider/otp-verify',
  PROVIDER_COMPLIANCE: '/provider/business-info',
  PROVIDER_DASHBOARD: '/provider/dashboard',
  PROVIDER_SETTINGS: '/provider/dashboard/settings',
  PROVIDER_SERVICES: '/provider/dashboard/services',
  PROVIDER_TRANSACTIONS: '/provider/dashboard/transactions',
  PROVIDER_TRANSACTIONS_DETAILS: '/provider/dashboard/transactions/:id',
  // PROVIDER_CUSTOMERS: '/provider/dashboard/customers',
  PROVIDER_PROFILE: '/provider/dashboard/settings/profile',
  PROVIDER_PASSWORD_RESET: '/provider/dashboard/settings/password-reset',
  PROVIDER_PRIVACY: '/provider/dashboard/settings/privacy-policy',
  CUSTOMER_DETAILS: '/provider/dashboard/customers/:id',
  // PROVIDER_LEGAL: '/provider/dashboard/legal',
  PROVIDER_COOKIES_POLICY: '/provider/dashboard/legal/cookies-policy',
  PROVIDER_TERMS: '/provider/dashboard/legal/terms',
  PROVIDER_PRIVACY_POLICY: '/provider/dashboard/legal/policy',
  PROVIDER_WALLET: '/provider/dashboard/wallet',
});
