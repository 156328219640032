import { MdClose } from 'react-icons/md';
import { useState } from 'react';
import Modal from 'components/shared/modal';
import { useFormik } from 'formik';
import * as yup from 'yup';
import InputField from 'components/shared/input-field';
import { appAxios } from 'api/axios';
import { getTokenDetails } from 'functions/userSession';
import { decodeToken } from 'react-jwt';
import { TokenType } from 'types/jwt';
import { Loader } from 'lucide-react';
import { formatToNaira } from 'functions/currencyConveter';
import { sendCatchFeedback, sendFeedback } from 'functions/feedback';
import AuthAnimatedModal from 'components/shared/modal/AuthAnimatedModal';
import { toast } from 'react-toastify';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  goalId: string;
};

const validationSchema = yup.object({
  amount: yup.number().required('Amount is required'),
});

function AddSavingsModal({ isOpen, onClose, goalId }: Props) {
  const token = getTokenDetails();
  const decodedToken = decodeToken<TokenType>(token!);
  const customerId = decodedToken?.customerId;
  const [loading, setLoading] = useState(false);
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);

  const formik = useFormik({
    initialValues: {
      customerId: customerId,
      amount: '',
      savingsGoalId: goalId,
    },
    validationSchema,
    onSubmit: async (values) => {
      console.log('Form submitted with values:', values);
      setLoading(true);
      try {
        const { customerId, amount, savingsGoalId } = values;
        console.log('Making API call with:', {
          customerId,
          amount,
          savingsGoalId,
        });
        const { data } = await appAxios.post(
          `/add-contribution?&goalId=${savingsGoalId}&amount=${amount}`
        );
        toast(data?.message, { type: 'success' });
        setIsModalSuccessOpen(true);
        onClose();
      } catch (error) {
        console.error('API error:', error);
        sendCatchFeedback(error);
        onClose();
      } finally {
        setLoading(false);
      }
    },
  });

  const handleProceed = () => {
    setIsModalSuccessOpen(false);
    onClose();
    window.location.reload();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Add Savings"
      subtitle="Please enter the amount you want to add to your savings"
      width="400px"
    >
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
        <InputField
          name="amount"
          label="Amount to add"
          type="number"
          placeholder="Enter amount to add"
          value={formik.values.amount}
          onChange={(e) => {
            const value = (e.target as HTMLInputElement).value;
            if (/^\d*\.?\d*$/.test(value)) {
              formik.setFieldValue('amount', value);
            }
          }}
          onBlur={formik.handleBlur}
          error={formik.touched.amount ? formik.errors.amount : ''}
          maxLength={19}
        />
        <div className="flex gap-x-4 mt-4 mb-6">
          <button
            type="button"
            className="flex-1 border border-[#EFA005] outline-none rounded-lg text-[#EFA005] p-1"
            onClick={onClose}
            disabled={loading}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="flex-1 border border-[#EFA005] outline-none rounded-lg bg-[#EFA005] text-white p-1"
            disabled={loading}
          >
            {loading ? (
              <Loader className="mx-auto animate-spin" size={16} />
            ) : (
              'Add Savings'
            )}
          </button>
        </div>
      </form>

      {isModalSuccessOpen && (
        <AuthAnimatedModal
          isOpen={isModalSuccessOpen}
          onClose={handleProceed}
          onConfirm={handleProceed}
          title="Savings Added Successfully!"
          btnText="Proceed"
          content={
            <p>
              You have successfully added{' '}
              <span className="font-semibold">
                {formatToNaira(Number(formik.values.amount))}
              </span>{' '}
              to your savings. Thank you!
            </p>
          }
        />
      )}
    </Modal>
  );
}

export default AddSavingsModal;
