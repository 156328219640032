// src/App.tsx
import React, { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { default as ErrorBoundaryComponent } from './components/error-boundary';
import LoadingIndicator from './components/shared/loading-indicator';
import { getTokenDetails } from './functions/userSession';
import NotFound from './pages/NotFound';
import routes from './routes';
import { useAppDispatch } from './store/hooks';
import { updateToken } from './store/slices/user';
import 'rsuite/dist/rsuite.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';


const ErrorBoundaryLayout = () => (
  <ErrorBoundary FallbackComponent={ErrorBoundaryComponent}>
    <Outlet />
  </ErrorBoundary>
);

const SuspenseLayout = () => (
  <React.Suspense
    fallback={
      <div className="h-screen w-screen flex items-center justify-center">
        <LoadingIndicator size={100} />
      </div>
    }
  >
    <Outlet />
  </React.Suspense>
);

const App: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const token = getTokenDetails();
    // const refreshToken = localStorage.getItem('refreshToken');
    if (
      token
      // && refreshToken
    ) {
      dispatch(
        updateToken({
          token,
          // , refreshToken
        })
      );
    }
  }, [dispatch]);

  const router = createBrowserRouter([
    {
      element: <ErrorBoundaryLayout />,
      errorElement: <NotFound />,
      children: [
        {
          element: <SuspenseLayout />,
          children: routes,
        },
      ],
    },
  ]);

  return (
    <>
      <RouterProvider router={router} />
      <ToastContainer
        style={{
          fontSize: 16,
          zIndex: 30,
        }}
        theme="colored"
        autoClose={5000}
        position="top-right"
        hideProgressBar={true}
        closeOnClick={true}
      />
    </>
  );
};

export default App;
