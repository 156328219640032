import React, { useState, useEffect } from 'react';
import SavingsSummary from './SavingsSummary';
import Button from 'components/shared/button';
import styles from './BeginSavingsTarget.module.css';
import { appAxios } from 'api/axios';
import Select from 'react-select';
import SelectInput from 'components/shared/select-input';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { IoAlertCircle } from 'react-icons/io5';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import InputField from 'components/shared/input-field';
import Input from 'components/shared/input-field/inputAmountTags';
import { OptionType, SavingsFormData } from '../../../../types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { savingsSlice, selectInterestRate } from 'store/slices/savings';
import { toast } from 'react-toastify';
import './datepicker-custom.css';

type BeginSavingsProps = {
  goalName: string;
  targetAmount?: string;
  monthlyContribution?: string;
  savingCategory?: string;
  frequency?: string;
  startDate?: string;
  endDate?: string;
};
interface BeginSavingsTargetProps {
  handleNext: () => void;
  formValues: BeginSavingsProps;
  setFormValues: (data: BeginSavingsProps) => void;
}
function BeginSavingsTarget({
  handleNext,
  formValues,
  setFormValues,
}: BeginSavingsTargetProps) {
  const [dropdownOptions, setDropdownOptions] = useState('');
  const [dropdownSaveOptions, setDropdownSaveOptions] = useState('');
  const [loading, setLoading] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState<OptionType[]>([]);

  const dispatch = useAppDispatch();
  const savingsType = useAppSelector((state) => state.savings.savingsType);
  const interestRate = useAppSelector(selectInterestRate);

  useEffect(() => {
    // If interest rate is not set in redux store, try to get it from localStorage
    if (!interestRate) {
      const savedInterestRate = localStorage.getItem('jompVaultInterestRate');
      if (savedInterestRate) {
        const rate = parseFloat(savedInterestRate);
        dispatch(savingsSlice.actions.setInterestRate(rate));
      }
    }
  }, [interestRate, dispatch]);

  // const handleNameChange = (type: string) => {
  //   dispatch(setSavingsType(type));
  // };

  const durationOptions = [
    '1 Month',
    '3 Months',
    '6 Months',
    '9 Months',
    '1 year',
    'Customise',
  ];
  const [selectedDuration, setSelectedDuration] = useState<string | null>(null);
  const [customDate, setCustomDate] = useState<Date | null>(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [suggestedAmount, setSuggestedAmount] = useState<number | null>(null);

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: yup.object({
      goalName: yup.string().required('Title is required'),
      savingCategory: yup.string().required('Category is required'),
      frequency: yup.string().required('Savings frequency is required'),
      monthlyContribution: yup
        .string()
        .required('Your contribution is required'),
      startDate: yup.date().required('Start date is required'),
      targetAmount: yup.string().required('Amount is required'),
    }),
    onSubmit: (values) => {
      // Prevent default form submission
      // if (handleNext) {
      //   handleNext(values);
      // }
      setFormValues(values);
    },
  });

  useEffect(() => {
    setFormValues(formik.values);
  }, [formik.values]);

  const saveOptions: OptionType[] = [
    {
      label: 'Daily',
      value: 'daily',
    },
    {
      label: 'Weekly',
      value: 'weekly',
    },
    {
      label: 'Monthly',
      value: 'monthly',
    },
  ];

  // Modify this utility function to standardize date formatting
  const formatDate = (date: Date | null) => {
    if (!date) return '';
    // Return ISO format string (YYYY-MM-DD) for consistent storage
    return date.toISOString().split('T')[0];
  };

  const handleDurationClick = (duration: string) => {
    setSelectedDuration(duration);

    // Get the existing start date or use current date if not set
    const startDate = formik.values.startDate
      ? new Date(formik.values.startDate)
      : new Date();
    let endDate = new Date(startDate);

    if (duration === 'Customise') {
      setShowCalendar(true);
      setCustomDate(null);
      // Don't override start date, let the user choose from the date picker
      // Only calculate end date if needed
    } else {
      setShowCalendar(false);
      setCustomDate(null);

      const monthsToAdd =
        duration === '1 year' ? 12 : parseInt(duration.split(' ')[0], 10);
      endDate.setMonth(startDate.getMonth() + monthsToAdd);

      // Only set end date, keep start date as selected by user
      formik.setFieldValue('endDate', formatDate(endDate));
    }
  };

  const handleSelectChange = (selectedOption: OptionType | null) => {
    setDropdownOptions(selectedOption!.value);
  };

  useEffect(() => {
    const getCategories = async () => {
      try {
        setLoading(true);
        const { data } = await appAxios.get(`/get-saving-category`);
        if (data && data.statusCode === 200) {
          const formattedOptions = data.data.map((category: any) => ({
            label: category.name,
            value: category.id,
          }));
          setCategoryOptions(formattedOptions);
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    getCategories();
  }, []);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index?: number
  ) => {
    const { name, value } = e.target;

    const plainValue = value.replace(/,/g, '');
    if (name === `targetAmount` && index !== undefined) {
      const formattedValue = formatWithCommas(plainValue);
      formik.setFieldValue(`targetAmount`, formattedValue);
      formik.handleChange(e);
    } else if (name === 'monthlyContribution') {
      setAmount(Number(plainValue));
    } else {
      formik.handleChange(e);
    }

    // clearErrors(name);
  };

  const formatWithCommas = (value: string) => {
    const sanitizedValue = value.replace(/[^0-9]/g, '');
    return sanitizedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  // Extract calculation function so it can be called from multiple places
  const calculateSuggestedAmount = (contributionValue?: string) => {
    // Use the provided contribution or the one in formik
    const monthlyContribution =
      contributionValue || formik.values.monthlyContribution;

    if (!formik.values.targetAmount || !formik.values.frequency) return;

    try {
      // Parse target amount, removing commas
      const targetAmount = Number(formik.values.targetAmount.replace(/,/g, ''));
      if (isNaN(targetAmount) || targetAmount <= 0) {
        console.log('Invalid target amount:', formik.values.targetAmount);
        return;
      }

      // If we don't have both dates yet, use a default calculation
      if (!formik.values.startDate || !formik.values.endDate) {
        let periods = 1;
        switch (formik.values.frequency) {
          case 'daily':
            periods = 30; // Default to 30 days
            break;
          case 'weekly':
            periods = 4; // Default to 4 weeks
            break;
          case 'monthly':
            periods = 1; // Default to 1 month
            break;
        }
        const calculatedAmount = Math.ceil(targetAmount / periods);
        setSuggestedAmount(calculatedAmount);
        return;
      }

      // Parse dates carefully
      const startDateStr = formik.values.startDate;
      const endDateStr = formik.values.endDate;

      console.log('Calculating with dates:', startDateStr, endDateStr);

      // Create date objects and handle potential parsing issues
      const startDate = new Date(startDateStr);
      const endDate = new Date(endDateStr);

      // Validate dates
      if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
        console.log('Invalid date format:', startDateStr, endDateStr);
        return;
      }

      // Ensure end date is after start date
      if (endDate <= startDate) {
        console.log('End date must be after start date');
        return;
      }

      // Calculate time difference in milliseconds
      const timeDiff = endDate.getTime() - startDate.getTime();
      const daysDiff = Math.max(1, Math.ceil(timeDiff / (1000 * 3600 * 24)));

      console.log('Days difference:', daysDiff);

      let totalPeriods = 1;

      switch (formik.values.frequency) {
        case 'daily':
          totalPeriods = daysDiff;
          break;
        case 'weekly':
          totalPeriods = Math.max(1, Math.ceil(daysDiff / 7));
          break;
        case 'monthly':
          // Calculate months between dates
          const months =
            (endDate.getFullYear() - startDate.getFullYear()) * 12 +
            (endDate.getMonth() - startDate.getMonth());

          totalPeriods = Math.max(1, months);
          break;
      }

      console.log('Total periods:', totalPeriods);

      const calculatedAmount = Math.ceil(targetAmount / totalPeriods);
      console.log('Calculated amount:', calculatedAmount);
      setSuggestedAmount(calculatedAmount);
    } catch (error) {
      console.error('Error calculating suggested amount:', error);
      setSuggestedAmount(null);
    }
  };

  const [amount, setAmount] = useState<number | null>(null);
  const [amountExceedsGoal, setAmountExceedsGoal] = useState(false);

  const handleAmountChange = (value: number) => {
    setAmount(value);

    // Check if the amount exceeds the target
    if (formik.values.targetAmount) {
      const parsedTarget = Number(formik.values.targetAmount.replace(/,/g, ''));
      setAmountExceedsGoal(value > parsedTarget);
    }

    // Directly set the formik value here instead of waiting for the useEffect
    formik.setFieldValue('monthlyContribution', value.toString());

    // Manually calculate suggested amount if we have the necessary values
    if (formik.values.targetAmount && formik.values.frequency) {
      calculateSuggestedAmount(value.toString());
    }
  };

  useEffect(() => {
    if (amount !== null) {
      formik.setFieldValue('monthlyContribution', amount.toString());

      // Verify again when either amount or targetAmount changes
      if (formik.values.targetAmount) {
        const parsedTarget = Number(
          formik.values.targetAmount.replace(/,/g, '')
        );
        setAmountExceedsGoal(amount > parsedTarget);
      }
    }
  }, [amount, formik.values.targetAmount]);

  useEffect(() => {
    if (
      formik.values.startDate &&
      formik.values.frequency &&
      selectedDuration === 'Customise'
    ) {
      const startDate = new Date(formik.values.startDate);
      let endDate = new Date(startDate); // Clone startDate

      const targetAmount = Number(formik.values.targetAmount);
      const contribution = Number(formik.values.monthlyContribution);
      const frequency = formik.values.frequency;

      if (!isNaN(targetAmount) && !isNaN(contribution) && contribution > 0) {
        const totalPeriods = Math.ceil(targetAmount / contribution); // Calculate total periods

        switch (frequency) {
          case 'daily':
            endDate.setDate(startDate.getDate() + totalPeriods);
            break;
          case 'weekly':
            endDate.setDate(startDate.getDate() + totalPeriods * 7);
            break;
          case 'monthly':
            endDate.setMonth(startDate.getMonth() + totalPeriods);
            break;
          default:
            return;
        }

        // Format the date to "YYYY-MM-DD" before setting it
        const formattedEndDate = endDate.toISOString().split('T')[0];

        if (formik.values.endDate !== formattedEndDate) {
          formik.setFieldValue('endDate', formattedEndDate);
        }
      }
    }
  }, [
    formik.values.startDate,
    formik.values.frequency,
    formik.values.targetAmount,
    formik.values.monthlyContribution,
  ]);

  // Run calculation on relevant field changes
  useEffect(() => {
    calculateSuggestedAmount();
  }, [
    formik.values.targetAmount,
    formik.values.frequency,
    formik.values.startDate,
    formik.values.endDate,
  ]);

  return (
    <section className="">
      <div className="flex flex-col gap-y-[10px] mb-[30px]">
        {/* <Link to="/" className="flex gap-x-2 text-[#EFA005]">
          <ArrowLeft2 /> Back
        </Link> */}
        <div className="flex flex-col justify-between items-center lg:flex-row">
          <div className="mb-4 lg:mb-0">
            <h1 className="font-semibold">
              Create a JompReserve Target Savings
            </h1>
            <p>
              Set up a new savings target and get paid everyday (@{' '}
              {interestRate}% interest P.A) to reach your goals faster
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col-reverse lg:flex-row gap-x-[50px]">
        <main className=" flex-1 bg-white rounded-md px-[15px] py-[20px] lg:w-[60%]">
          <header className="flex flex-col gap-y-[3px] mb-[30px]">
            <h1 className="font-semibold text-[20px]">Start Savings</h1>
            <div className="flex gap-3 items-center p-2 rounded-md bg-[#F0EDFF] ">
              <IoAlertCircle className="text-[#31005C] w-8 h-8" />
              <p className="text-xs">
                Set up a new savings target and get paid every day (@{' '}
                {interestRate}% interest P.A) to reach your goals faster.
              </p>
            </div>

            <div className="h-[1px] bg-gray-3 mt-[5px]"></div>
          </header>
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-col gap-y-[20px]"
          >
            <div>
              <InputField
                label="Savings Title"
                type="text"
                name="goalName"
                placeholder="e.g Traveling to zanzibar"
                value={formik.values.goalName}
                onChange={formik.handleChange}
                required
                onBlur={formik.handleBlur}
                error={formik.errors.goalName}
              />
            </div>

            <div>
              <InputField
                label="Savings Goal (Set Savings Goal)"
                type="text"
                name="targetAmount"
                placeholder="e.g 500000"
                value={formatWithCommas(formik.values.targetAmount || '')}
                onChange={handleInputChange}
                required
                onBlur={formik.handleBlur}
                error={formik.errors.targetAmount}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Set Savings Duration
              </label>
              <div className="flex gap-3 mt-2">
                {durationOptions.map((option) => (
                  <Button
                    key={option}
                    variant="outlined"
                    onClick={() => handleDurationClick(option)}
                    className={`p-2 border rounded-md text-[#EFA005] ${
                      selectedDuration === option
                        ? 'bg-[#EFA0051A] border-[#EFA005] border-2 text-[#EFA005]'
                        : 'transparent border-[#EFA005]'
                    }`}
                  >
                    {option}
                  </Button>
                ))}
              </div>

              {/* Date picker for start date */}
              <div className="mt-4 w-full rounded-md">
                <label className="block text-sm font-medium text-gray-700">
                  Choose Start Date
                </label>
                <div className="mt-1">
                  <DatePicker
                    selected={
                      formik.values.startDate
                        ? new Date(formik.values.startDate)
                        : null
                    }
                    onChange={(date: Date | null) => {
                      if (date) {
                        formik.setFieldValue('startDate', formatDate(date));

                        // Calculate end date based on selected duration
                        if (
                          selectedDuration &&
                          selectedDuration !== 'Customise'
                        ) {
                          let endDate = new Date(date);
                          const monthsToAdd =
                            selectedDuration === '1 year'
                              ? 12
                              : parseInt(selectedDuration.split(' ')[0], 10);
                          endDate.setMonth(date.getMonth() + monthsToAdd);
                          formik.setFieldValue('endDate', formatDate(endDate));
                        }
                      }
                    }}
                    dateFormat="yyyy-MM-dd"
                    minDate={new Date()}
                    className="datepicker-full-width w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#EFA005] focus:border-transparent"
                    placeholderText="Select start date"
                    wrapperClassName="date-picker-wrapper w-full"
                  />
                  {formik.errors.startDate && formik.touched.startDate && (
                    <p className="mt-1 text-sm text-red-500">
                      {formik.errors.startDate}
                    </p>
                  )}
                </div>
              </div>

              {/* Show calendar if "Customise" is selected */}
              {showCalendar && (
                <div className="flex gap-y-[20px] mt-3 flex-col lg:flex-row lg:gap-x-[20px]">
                  <div className="w-full flex justify-between py-1 dark:focus:border-[#EFA005] outline-none items-center ">
                    <InputField
                      label="Customise your end date"
                      type="date"
                      name="endDate"
                      placeholder="e.g dd/mm/yy"
                      value={formik.values.endDate}
                      required
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.errors.endDate}
                    />
                  </div>
                </div>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Savings Duration (Set Savings Duration)
              </label>
              <div className="flex gap-3 mt-2">
                {saveOptions.map((option) => (
                  <Button
                    variant="outlined"
                    key={option.value}
                    onClick={() =>
                      formik.setFieldValue('frequency', option.value)
                    }
                    className={`px-4 py-2 border rounded-md text-[#EFA005] ${
                      formik.values.frequency === option.value
                        ? 'bg-[#EFA0051A] border-[#EFA005] border-2 text-[#EFA005]'
                        : 'transparent border-[#EFA005] '
                    }`}
                  >
                    {option.label}
                  </Button>
                ))}
              </div>
              {formik.errors.frequency && (
                <p className="mt-1 text-sm text-red-500">
                  {formik.errors.frequency}
                </p>
              )}
            </div>
            {/* The suggested amount(which is the amount) should be based on targetAmount, monthlyContribution and frequency */}
            <div className="flex gap-3 items-center p-2 rounded-md bg-[#F0EDFF] ">
              <p className="text-xs">
                Based on your selection above, you can be saving (₦
                {suggestedAmount?.toLocaleString()}) per{' '}
                {formik.values.frequency === 'daily'
                  ? 'day'
                  : formik.values.frequency === 'weekly'
                    ? 'week'
                    : 'month'}
                .
              </p>
            </div>

            <Input
              label="Preferred amount to save"
              options={[500, 1000, 1500, 5000, 10000]}
              onChange={handleAmountChange}
              required
            />
            {amountExceedsGoal && (
              <div className="flex gap-3 items-center p-2 rounded-md bg-[#FFF0F0] border border-red-400">
                <IoAlertCircle className="w-5 h-5 text-red-500" />
                <p className="text-xs text-red-600">
                  Your preferred amount to save cannot be more than your savings
                  goal.
                </p>
              </div>
            )}

            {/* Select Category */}
            <SelectInput
              label="Select a Category"
              name="savingCategory"
              options={categoryOptions}
              placeholder="Select Category..."
              value={
                categoryOptions.find(
                  (option) => option.label === formik.values.savingCategory
                ) || null
              }
              onChange={(name, value) =>
                formik.setFieldValue(name, value?.label)
              }
              required
              error={formik.errors.savingCategory}
            />

            <Button
              type="button"
              onClick={(e: React.FormEvent) => {
                e.preventDefault();
                formik.validateForm().then((errors) => {
                  if (Object.keys(errors).length === 0 && !amountExceedsGoal) {
                    handleNext();
                  } else {
                    // Touch all fields to display errors
                    const touchedFields = Object.keys(formik.values).reduce(
                      (acc, key) => ({ ...acc, [key]: true }),
                      {}
                    );
                    formik.setTouched(touchedFields);

                    if (amountExceedsGoal) {
                      toast.error(
                        'Your preferred amount to save cannot be more than your savings goal.'
                      );
                    } else {
                      toast.error(
                        'Please provide all required fields before proceeding.'
                      );
                    }
                  }
                });
              }}
              disabled={amountExceedsGoal}
              className={`${
                amountExceedsGoal ? 'bg-gray-400' : 'bg-[#EFA005]'
              } text-white ml-auto px-12 py-2 text-sm rounded-md`}
            >
              Proceed
            </Button>
          </form>
        </main>

        <SavingsSummary formValues={formValues} />
      </div>
    </section>
  );
}

export default BeginSavingsTarget;
