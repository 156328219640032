import { FC } from 'react';
import { FallbackProps } from 'react-error-boundary';
import MainLayout from '../../layouts/MainLayout';
import Button from '../shared/button';

const ErrorBoundary: FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  return (
    <MainLayout>
      <div
        role="alert"
        className="h-screen w-screen flex flex-col items-center justify-center text-BrandSecondary"
      >
        <p className="text-2xl mb-2 font-semibold">
          Oops! Something went wrong:
        </p>
        {/* <p className='text-red-error mb-4'>{error?.message}</p> */}
        <Button onClick={resetErrorBoundary}>Reload</Button>
      </div>
    </MainLayout>
  );
};

export default ErrorBoundary;
