// import React, { ReactHTML, ReactNode } from 'react';
import { Eye, EyeOffIcon, Vault } from 'lucide-react';
// import { cardDetailProps } from 'types';
// import { viewAmountProps } from 'types';
// import { Link } from 'react-router-dom';
// import { howJompWorksProps } from 'types';
// import { progress } from 'framer-motion';
import { RoutePaths } from 'routes/route-paths';

export const cardDetails = [
  {
    title: 'JompVault ',
    description:
      'Reach your unique individual savings goals for 45 days to 90 days with 12% interest P.A',
    value: '0.00',
    icon: <Eye />,
    image: '/svgs/Vault.svg',
    id: '1',
  },
  {
    title: 'JompReserve',
    description:
      'Reach your unique individual savings goal for 180 days with 12% interest P.A',
    value: '0.00',
    icon: <Eye />,
    image: '/svgs/Vault2.svg',
    id: '2',
  },
  {
    title: 'Total Savings',
    description: 'Long term savings goals for 540 days with 12% interest P.A',
    value: '0.00',
    icon: <Eye />,
    image: '/svgs/Fortress.svg',
    id: '3',
  },
];

export const viewAmount = {
  image: '/svgs/Wallet.svg',
  title: 'Total Savings Balance',
  value: 20000,
  eye: <Eye />,
  id: '1',
};

export const jompVaultData = [
  {
    title: 'JompVault ',
    description:
      'Reach your unique individual savings goals for 45 days to 90 days with 12% interest P.A',
    link: RoutePaths.USER_SAVINGS_JOMPVAULT,
    image: '/svgs/Vault.svg',
    id: '1',
  },
  {
    title: 'JompReserve',
    description: 'Coming soon',
    // 'Reach your unique individual savings goal for 180 days with 12% interest P.A',
    link: '#',
    image: '/svgs/Vault2.svg',
    id: '2',
  },
  {
    title: 'JompFortress',
    description: 'Coming soon',
    // description: 'Long term savings goals for 540 days with 12% interest P.A',
    link: '#',
    image: '/svgs/Fortress.svg',
    id: '3',
  },
];

export const howJompWorks = [
  {
    title: 'Set Your Goal',
    description:
      'Planning for something special in the next month or two? Whether it’s a grooming appointment, a saloon, or car repairs, JompVault helps you save specifically for these short-term goals',
    firstGoal: '',
    secondGoal: '',
    image: '/svgs/Goal.svg',
    bg: '#FFF9E6',
    id: '1',
  },
  {
    title: 'Choose Your Savings Period',
    description: 'Pick a savings duration that works for you:',
    firstGoal: '45 Days: For goals coming up in the next month.',
    secondGoal: '90 Days: For goals just around the corner in two months.',
    image: '/svgs/Calender.svg',
    bg: '#F2FFFD',
    id: '2',
  },
  {
    title: 'Track Your Progress',
    description:
      "Easily monitor how close you are to reaching your goal. You'll see your progress in real time as you save with reminders to keep you on track",
    firstGoal: '',
    secondGoal: '',
    image: '/svgs/Graph.svg',
    bg: '#F9F8FF',
    id: '3',
  },
  {
    title: 'Unlock and Use Your Vault',
    description:
      "Once you reach your savings target, your JompVault unlocks, and you're all set to use your funds for your planned appointment or expense.",
    firstGoal: '',
    secondGoal: '',
    image: '/svgs/Unlock.svg',
    bg: '#FFF7F8',
    id: '4',
  },
];

export const itemsOnYourVault = [
  {
    id: '1',
    itemPhoto: '/svgs/house.svg',
    itemTitle: 'House Rent',
    itemValue: '1000',
    maturationIcon: '/svgs/padlock.svg',
    maturationDuration: 'Matures in 4 weeks',
    progress: '/svgs/canvasHundred.svg',
  },
  {
    id: '2',
    itemPhoto: '/svgs/Stethoscope.svg',
    itemTitle: 'Medical Bills',
    itemValue: '2 000 000',
    maturationIcon: '/svgs/padlock.svg',
    maturationDuration: 'Matures in 4 weeks',
    progress: '/svgs/canvasZero.svg',
  },
];

export const transactions = [
  {
    dateAndTime: '27 Aug 2020 3:08 PM',
    description: 'JompVault savings top up',
    transactionType: 'savings',
    amount: '₦2,000,000.00',
    status: 'pending',
  },
  {
    dateAndTime: '28 Aug 2020 10:45 AM',
    description: 'JompVault savings top up',
    transactionType: 'savings',
    amount: '₦1,500,000.00',
    status: 'successful',
  },
  {
    dateAndTime: '29 Aug 2020 2:30 PM',
    description: 'JompVault savings top up',
    transactionType: 'savings',
    amount: '₦3,000,000.00',
    status: 'successful',
  },
  {
    dateAndTime: '30 Aug 2020 5:15 PM',
    description: 'JompVault savings top up',
    transactionType: 'savings',
    amount: '₦2,500,000.00',
    status: 'successful',
  },
  {
    dateAndTime: '31 Aug 2020 9:20 AM',
    description: 'JompVault savings top up',
    transactionType: 'savings',
    amount: '₦1,200,000.00',
    status: 'successful',
  },
  {
    dateAndTime: '01 Sep 2020 11:45 AM',
    description: 'JompVault savings top up',
    transactionType: 'savings',
    amount: '₦2,800,000.00',
    status: 'successful',
  },
  {
    dateAndTime: '02 Sep 2020 3:30 PM',
    description: 'JompVault savings top up',
    transactionType: 'savings',
    amount: '₦1,000,000.00',
    status: 'successful',
  },
  {
    dateAndTime: '03 Sep 2020 10:00 AM',
    description: 'JompVault savings top up',
    transactionType: 'savings',
    amount: '₦3,500,000.00',
    status: 'successful',
  },
  {
    dateAndTime: '04 Sep 2020 12:45 PM',
    description: 'JompVault savings top up',
    transactionType: 'savings',
    amount: '₦2,200,000.00',
    status: 'successful',
  },
  {
    dateAndTime: '05 Sep 2020 8:30 AM',
    description: 'JompVault savings top up',
    transactionType: 'savings',
    amount: '₦4,000,000.00',
    status: 'successful',
  },
  {
    dateAndTime: '06 Sep 2020 4:15 PM',
    description: 'JompVault savings top up',
    transactionType: 'savings',
    amount: '₦2,700,000.00',
    status: 'successful',
  },
  {
    dateAndTime: '07 Sep 2020 6:50 AM',
    description: 'JompVault savings top up',
    transactionType: 'savings',
    amount: '₦1,800,000.00',
    status: 'successful',
  },
  {
    dateAndTime: '08 Sep 2020 2:10 PM',
    description: 'JompVault savings top up',
    transactionType: 'savings',
    amount: '₦2,600,000.00',
    status: 'successful',
  },
  {
    dateAndTime: '09 Sep 2020 11:00 AM',
    description: 'JompVault savings top up',
    transactionType: 'savings',
    amount: '₦3,200,000.00',
    status: 'successful',
  },
  {
    dateAndTime: '10 Sep 2020 9:40 AM',
    description: 'JompVault savings top up',
    transactionType: 'savings',
    amount: '₦1,400,000.00',
    status: 'successful',
  },
  {
    dateAndTime: '11 Sep 2020 5:35 PM',
    description: 'JompVault savings top up',
    transactionType: 'savings',
    amount: '₦3,800,000.00',
    status: 'successful',
  },
  {
    dateAndTime: '12 Sep 2020 7:25 AM',
    description: 'JompVault savings top up',
    transactionType: 'savings',
    amount: '₦2,300,000.00',
    status: 'successful',
  },
  {
    dateAndTime: '13 Sep 2020 10:55 AM',
    description: 'JompVault savings top up',
    transactionType: 'savings',
    amount: '₦3,100,000.00',
    status: 'successful',
  },
  {
    dateAndTime: '14 Sep 2020 4:20 PM',
    description: 'JompVault savings top up',
    transactionType: 'savings',
    amount: '₦2,500,000.00',
    status: 'successful',
  },
  {
    dateAndTime: '15 Sep 2020 8:15 AM',
    description: 'JompVault savings top up',
    transactionType: 'savings',
    amount: '₦1,900,000.00',
    status: 'successful',
  },
];

// export const savingsSummaryProps = {
//   savingsTitle: number;
//   interestRate: string;
//   savingsDuration: number;
//   autoSave: boolean;
//   amountToSave: number;
//   frequency: string;
// };

/* 
<SumBalanceModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        title="Balance Information"
        subtitle="Manage your balance information"
        btnText="Pay Now"
        // onConfirm={() => setIsModalOpen(false)}
        content={<div></div>}
        amountData={statusData}
      />
*/

// export const modalData = [
//   {
//     isOpen: false,
//     title: 'Balance Information',
//     subtitle: 'Manage your balance information',
//     btnText: 'Start Savings',
//     content: <div></div>,
//   },
// ];
