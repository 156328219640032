import React from 'react';
import Modal from './index';
import Button from '../button';

type CardDisclaimerModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onProceed: () => void;
};

const CardDisclaimerModal: React.FC<CardDisclaimerModalProps> = ({
  isOpen,
  onClose,
  onProceed,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Card Payment Disclaimer"
      subtitle="Important information about your card details"
      width="500px"
    >
      <div className="flex flex-col gap-4">
        <div className="bg-yellow-50 p-4 rounded-md border border-yellow-200">
          <h3 className="text-lg font-medium text-yellow-800 mb-2">Please Note:</h3>
          <p className="text-yellow-700 mb-2">
            For your security, your card details will not be saved when you proceed with this payment.
          </p>
          <p className="text-yellow-700">
            Your payment will be securely processed by Paystack, our trusted payment provider.
          </p>
        </div>
        
        <div className="flex justify-end gap-3 mt-4">
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onProceed}>
            Proceed to Payment
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CardDisclaimerModal; 
