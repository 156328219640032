import React from 'react';


interface SavingsSummaryProps {
  formValues: {
    goalName?: string;
    targetAmount?: string;
    savingCategory?: string;
    frequency?: string;
    monthlyContribution?: string;
    startDate?: string;
    endDate?: string;
    preferredTime?: string;
    autoSave?: boolean;
    autoWithdrawal?: boolean;
    status?: string;
    savingSource?: string;
    interestTagentSaving?: boolean;
    targetBreakBeforeEndDate?: boolean;
    savingsTime?: string;
    cardDetails?: {
      cardNumber: string;
      expiryMonth: string;
      expiry_year: string;
      cvv: string;
    };
  };
}
// interface BeginSavingsTargetProps {
//   formValues: BeginSavingsProps;
// }

function SavingsSummary({ formValues }: SavingsSummaryProps) {
  console.log(formValues, 'formValues');

  const formatDate = (dateString?: string) => {
    if (!dateString) return ''; // Return empty string if undefined

    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  };
  return (
    <div className="lg:w-[40%]">
      <aside className="bg-white p-2 rounded-md px-[20px] py-[30px]">
        <h2 className="font-semibold">Saving Summary</h2>
        <div className="h-[1px] bg-gray-3 mt-2 mb-6"></div>
        <table className="w-full flex flex-col gap-y-[10px] pt-1 pb-1">
          <tr>
            <tr className="flex gap-x-[60px] text-[12px] text-gray-500">
              <td className="flex-1">Savings Title</td>
              <td className="flex-1">How will you like to save?</td>
              {/* <td className="flex-1">Interest Rate</td> */}
            </tr>
            <tr className="flex gap-x-[60px] font-semibold text-[14px] mb-3 capitalize">
              <td className="flex-1">{formValues?.goalName}</td>
              <td className="flex-1">{formValues?.frequency}</td>
              {/* <td className="flex-1">12% P.A</td> */}
            </tr>
            <div className="h-[1px] bg-gray-3 my-[7px]"></div>
            <tr className="flex gap-x-[60px] text-[12px] text-gray-500">
              {/* <td className="flex-1">Savings Duration</td> */}
              {/* <td className="flex-1">How will you like to save?</td> */}
              {/* <td className="flex-1">Interest Rate</td> */}
            </tr>
            <tr className="flex gap-x-[60px] font-semibold text-[14px]">
              {/* <td className="flex-1">{formValues?.targetAmount}</td> */}
              {/* <td className="flex-1">{formValues?.autoSave ? 'Yes' : 'No'}</td> */}
              {/* <td className="flex-1">12% P.A</td> */}
            </tr>
            <tr className="flex gap-x-[60px] text-[12px] text-gray-500">
              <td className="flex-1">Preferred Amount to Save on a Basis</td>
              <td className="flex-1">Do You Want to Enable Auto Savings?</td>
              {/* <td className="flex-1">Interest Rate</td> */}
            </tr>
            <tr className="flex gap-x-[60px] font-semibold text-[14px] mb-3 capitalize">
              <td className="flex-1">{formValues?.monthlyContribution}</td>
              <td className="flex-1">{formValues?.autoSave ? 'Yes' : 'No'}</td>
              {/* <td className="flex-1">12% P.A</td> */}
            </tr>
            <div className="h-[1px] bg-gray-3 my-[7px]"></div>
            <tr className="flex gap-x-[60px] text-[12px] text-gray-500">
              <td className="flex-1">Source of Funding</td>
              <td className="flex-1">
                Auto Withdrawal at The End of Your Savings Duration?
              </td>
            </tr>
            <tr className="flex gap-x-[60px] font-semibold text-[14px] mb-3 capitalize">
              <td className="flex-1">{formValues?.savingCategory}</td>
              <td className="flex-1">
                {formValues?.autoWithdrawal ? 'Yes' : 'No'}
              </td>
            </tr>
          </tr>
          <div className="h-[1px] bg-gray-3 my-[7px]"></div>

          <tr>
            <tr className="flex gap-x-[60px] text-[12px] text-gray-500">
              <td className="flex-1">Start Date</td>
              <td className="flex-1">Maturity Date</td>
            </tr>
            <tr className="flex gap-x-[60px] font-semibold text-[14px]">
              <td className="flex-1">{formatDate(formValues?.startDate)}</td>
              <td className="flex-1">{formatDate(formValues?.endDate)}</td>
            </tr>
          </tr>
        </table>
      </aside>
    </div>
  );
}

export default SavingsSummary;
