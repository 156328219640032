import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import Button from '../button';
import { formatToNaira } from 'functions/currencyConveter';
import InputField from 'components/shared/input-field';
import Input from 'components/shared/input-field/inputAmountTags';
import { IoAlertCircle } from 'react-icons/io5';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { appAxios } from 'api/axios';
import { sendCatchFeedback, sendFeedback } from 'functions/feedback';

interface DetailItem {
  label: string;
  value: string;
  isPercentage?: boolean;
  isInterest?: boolean;
  isSuccess?: boolean;
}

interface ReinvestModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  amount: string;
  details: DetailItem[];
  onReinvest?: (values: any) => void;
  goalId?: string;
}

const validationSchema = yup.object({
  newSavingsGoal: yup.string().required('Savings goal is required'),
  savingsDuration: yup.string().required('Duration is required'),
  savingsFrequency: yup.string().required('Savings frequency is required'),
  preferredAmount: yup.number().required('Preferred amount is required').min(1, 'Amount must be greater than 0'),
  startDate: yup.date().when('savingsDuration', {
    is: 'Customize',
    then: () => yup.date().required('Start date is required'),
    otherwise: () => yup.date().nullable(),
  }),
  endDate: yup.date().when('savingsDuration', {
    is: 'Customize',
    then: () => yup.date().required('End date is required').min(yup.ref('startDate'), 'End date must be after start date'),
    otherwise: () => yup.date().nullable(),
  }),
});

const ReinvestModal: React.FC<ReinvestModalProps> = ({
  isOpen,
  onClose,
  title,
  amount,
  details,
  onReinvest,
  goalId
}) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [amountExceedsGoal, setAmountExceedsGoal] = useState(false);
  const [suggestedAmount, setSuggestedAmount] = useState<number | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const durationOptions = ['1 Month', '3 Months', '6 Months', '9 Months', '1 Year', 'Customize'];
  const frequencyOptions = ['Daily', 'Weekly', 'Monthly'];

  const formik = useFormik({
    initialValues: {
      newSavingsGoal: '',
      savingsDuration: '',
      savingsFrequency: '',
      preferredAmount: 0,
      startDate: new Date().toISOString().split('T')[0],
      endDate: '',
      autoWithdrawal: false
    },
    validationSchema,
    onSubmit: async (values) => {
      if (amountExceedsGoal) return;
      
      try {
        setIsSubmitting(true);
        
        // Calculate duration in months based on start and end date
        const startDate = new Date(values.startDate);
        const endDate = new Date(values.endDate);
        const durationInMonths = 
          (endDate.getFullYear() - startDate.getFullYear()) * 12 + 
          (endDate.getMonth() - startDate.getMonth());
        
        // Format data for API request according to specified structure
        const requestData = {
          goalName: title,
          targetAmount: Number(values.newSavingsGoal.replace(/,/g, '')),
          monthlyContribution: values.preferredAmount,
          duration: durationInMonths,
          frequency: values.savingsFrequency,
          autoRew: values.autoWithdrawal, // autoRew as specified in the request body
          status: "Active"
        };
        
        // Call the API endpoint
        const response = await appAxios.post(`/create-reinvest-goal`,
          requestData
        );
        
        if (response.data && response.data.statusCode === 200) {
          sendFeedback("Reinvestment successful", "success");
          if (onReinvest) {
            onReinvest(values);
          }
          onClose();
        } else {
          throw new Error(response.data?.message || "Failed to create reinvestment");
        }
      } catch (error) {
        sendCatchFeedback(error);
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  // Update end date based on duration selection
  useEffect(() => {
    if (formik.values.savingsDuration && formik.values.savingsDuration !== 'Customize') {
      const start = new Date(formik.values.startDate);
      let end = new Date(start);
      
      if (formik.values.savingsDuration === '1 Month') {
        end.setMonth(start.getMonth() + 1);
      } else if (formik.values.savingsDuration === '3 Months') {
        end.setMonth(start.getMonth() + 3);
      } else if (formik.values.savingsDuration === '6 Months') {
        end.setMonth(start.getMonth() + 6);
      } else if (formik.values.savingsDuration === '9 Months') {
        end.setMonth(start.getMonth() + 9);
      } else if (formik.values.savingsDuration === '1 Year') {
        end.setFullYear(start.getFullYear() + 1);
      }
      
      formik.setFieldValue('endDate', end.toISOString().split('T')[0]);
    }
  }, [formik.values.savingsDuration, formik.values.startDate]);

  // Show calendar when 'Customize' is selected
  useEffect(() => {
    setShowCalendar(formik.values.savingsDuration === 'Customize');
  }, [formik.values.savingsDuration]);

  // Calculate suggested amount based on goal and duration
  useEffect(() => {
    const calculateSuggestedAmount = () => {
      if (!formik.values.newSavingsGoal || !formik.values.savingsFrequency) return;
      
      try {
        // Parse target amount, removing commas
        const targetAmount = Number(formik.values.newSavingsGoal.replace(/,/g, ''));
        if (isNaN(targetAmount) || targetAmount <= 0) {
          console.log("Invalid target amount:", formik.values.newSavingsGoal);
          return;
        }
        
        // If we don't have both dates yet, use a default calculation
        if (!formik.values.startDate || !formik.values.endDate) {
          let periods = 1;
          switch (formik.values.savingsFrequency.toLowerCase()) {
            case 'daily':
              periods = 30; // Default to 30 days
              break;
            case 'weekly':
              periods = 4;  // Default to 4 weeks
              break;
            case 'monthly':
              periods = 1;  // Default to 1 month
              break;
          }
          const calculatedAmount = Math.ceil(targetAmount / periods);
          setSuggestedAmount(calculatedAmount);
          return;
        }
        
        // Parse dates carefully
        const startDateStr = formik.values.startDate;
        const endDateStr = formik.values.endDate;
        
        console.log("Calculating with dates:", startDateStr, endDateStr);
        
        // Create date objects and handle potential parsing issues
        const startDate = new Date(startDateStr);
        const endDate = new Date(endDateStr);
        
        // Validate dates
        if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
          console.log("Invalid date format:", startDateStr, endDateStr);
          return;
        }
        
        // Ensure end date is after start date
        if (endDate <= startDate) {
          console.log("End date must be after start date");
          return;
        }
        
        // Calculate time difference in milliseconds
        const timeDiff = endDate.getTime() - startDate.getTime();
        const daysDiff = Math.max(1, Math.ceil(timeDiff / (1000 * 3600 * 24)));
        
        console.log("Days difference:", daysDiff);
        
        let totalPeriods = 1;
        
        switch (formik.values.savingsFrequency.toLowerCase()) {
          case 'daily':
            totalPeriods = daysDiff;
            break;
          case 'weekly':
            totalPeriods = Math.max(1, Math.ceil(daysDiff / 7));
            break;
          case 'monthly':
            // Calculate months between dates
            const months = (endDate.getFullYear() - startDate.getFullYear()) * 12 + 
                        (endDate.getMonth() - startDate.getMonth());
            
            totalPeriods = Math.max(1, months);
            break;
        }
        
        console.log("Total periods:", totalPeriods);
        
        const calculatedAmount = Math.ceil(targetAmount / totalPeriods);
        console.log("Calculated amount:", calculatedAmount);
        setSuggestedAmount(calculatedAmount);
      } catch (error) {
        console.error("Error calculating suggested amount:", error);
        setSuggestedAmount(null);
      }
    };

    calculateSuggestedAmount();
  }, [formik.values.newSavingsGoal, formik.values.savingsFrequency, formik.values.startDate, formik.values.endDate]);

  if (!isOpen) return null;

  const handleAmountChange = (value: number) => {
    formik.setFieldValue('preferredAmount', value);
    
    if (formik.values.newSavingsGoal) {
      const parsedTarget = Number(formik.values.newSavingsGoal.replace(/,/g, ''));
      setAmountExceedsGoal(value > parsedTarget);
    }
  };

  const formatWithCommas = (value: string) => {
    const sanitizedValue = value.replace(/[^0-9]/g, '');
    return sanitizedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const handleDurationClick = (duration: string) => {
    formik.setFieldValue('savingsDuration', duration);
  };

  return (
    <div className="flex overflow-y-auto fixed inset-0 z-50 justify-center items-center px-5 w-full h-full bg-gray-500 bg-opacity-50">
      <div className="relative p-6 w-full max-w-2xl bg-white rounded-lg shadow-lg max-h-[90vh] overflow-y-auto" style={{ scrollbarWidth: 'none' }}>
        <style>
          {`
            .modal-content::-webkit-scrollbar {
              display: none;
            }
          `}
        </style>
        <button
          type="button"
          className="absolute top-3 right-3 text-gray-400 hover:text-gray-900"
          onClick={onClose}
        >
          <X size={20} />
        </button>

        <h2 className="mb-4 text-xl font-semibold text-gray-800">Re-invest Savings</h2>

        {/* Current Savings Summary Card */}
        <div className="p-4 mb-4 text-center bg-gray-50 rounded-md">
          <p className="text-3xl font-bold text-gray-900">{amount}</p>
          <span className="inline-block px-3 py-1 mt-2 text-sm text-green-600 bg-green-100 rounded-lg">
            Ready to Reinvest
          </span>
        </div>

        {/* Current Savings Details */}
        <div className="mb-6 space-y-3">
          <div className="flex justify-between items-center text-gray-700">
            <span>Savings Title</span>
            <span className="font-medium">{title}</span>
          </div>
          {details.map((item, index) => (
            <div key={`${item.label}-${index}`} className="flex justify-between items-center text-gray-700">
              <span>{item.label}</span>
              <span className={`font-medium ${
                item.isSuccess ? 'text-green-600' : 
                item.isPercentage ? 'text-red-600' : ''
              }`}>
                {item.value}
              </span>
            </div>
          ))}
          <div className="flex justify-between items-center text-gray-700">
            <span>Goal Level</span>
            <span className="font-medium text-green-600">100%</span>
          </div>
          <div className="flex justify-between items-center text-gray-700">
            <span>Interest Earned</span>
            <span className="font-medium text-green-600">{formatToNaira(4047.23)}</span>
          </div>
          <div className="flex justify-between items-center text-gray-700">
            <span>Rolled Over Interest</span>
            <span className="font-medium text-green-600">{formatToNaira(40.47)}</span>
          </div>
        </div>

        <div className="h-[1px] bg-gray-200 my-6"></div>

        <h3 className="mb-4 text-lg font-semibold text-gray-800">Create New Savings</h3>

        {/* New Savings Form */}
        <form className="space-y-6" onSubmit={formik.handleSubmit}>
          <InputField
            label="Set New Savings Goal"
            type="text"
            name="newSavingsGoal"
            placeholder="e.g 500000"
            value={formatWithCommas(formik.values.newSavingsGoal)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => 
              formik.setFieldValue('newSavingsGoal', e.target.value)
            }
            onBlur={formik.handleBlur}
            error={formik.touched.newSavingsGoal ? formik.errors.newSavingsGoal : undefined}
            required
          />

          <div>
            <label className="block mb-2 text-sm font-medium text-gray-700">
              Set Savings Duration
            </label>
            <div className="flex flex-wrap gap-3">
              {durationOptions.map((option) => (
                <Button
                  key={option}
                  variant="outlined"
                  type="button" // Explicitly set type to prevent form submission
                  onClick={() => handleDurationClick(option)}
                  className={`px-4 py-2 border rounded-md text-[#EFA005] ${
                    formik.values.savingsDuration === option
                      ? 'bg-[#EFA0051A] border-[#EFA005] border-2'
                      : 'transparent border-[#EFA005]'
                  }`}
                >
                  {option}
                </Button>
              ))}
            </div>
            {formik.touched.savingsDuration && formik.errors.savingsDuration && (
              <p className="mt-1 text-sm text-red-500">{formik.errors.savingsDuration}</p>
            )}
          </div>

          {/* Show calendar if "Customise" is selected */}
          {showCalendar && (
            <div className="flex flex-col gap-y-[20px] mt-3 lg:flex-row lg:gap-x-[20px]">
              <div className="w-full">
                <InputField
                  label="Customise your start date"
                  type="date"
                  name="startDate"
                  placeholder="e.g dd/mm/yy"
                  value={formik.values.startDate}
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.startDate ? formik.errors.startDate : undefined}
                />
              </div>
              <div className="w-full">
                <InputField
                  label="Customise your end date"
                  type="date"
                  name="endDate"
                  placeholder="e.g dd/mm/yy"
                  value={formik.values.endDate}
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.endDate ? formik.errors.endDate : undefined}
                />
              </div>
            </div>
          )}

          <div>
            <label className="block mb-2 text-sm font-medium text-gray-700">
              How will you like to save?
            </label>
            <div className="flex flex-wrap gap-3">
              {frequencyOptions.map((option) => (
                <Button
                  key={option}
                  variant="outlined"
                  type="button" // Explicitly set type to prevent form submission
                  onClick={() => formik.setFieldValue('savingsFrequency', option.toLowerCase())}
                  className={`px-4 py-2 border rounded-md text-[#EFA005] ${
                    formik.values.savingsFrequency === option.toLowerCase()
                      ? 'bg-[#EFA0051A] border-[#EFA005] border-2'
                      : 'transparent border-[#EFA005]'
                  }`}
                >
                  {option}
                </Button>
              ))}
            </div>
            {formik.touched.savingsFrequency && formik.errors.savingsFrequency && (
              <p className="mt-1 text-sm text-red-500">{formik.errors.savingsFrequency}</p>
            )}
          </div>

          {suggestedAmount && (
            <div className="flex gap-3 items-center p-2 rounded-md bg-[#F0EDFF]">
              <p className="text-xs">
                Based on your selection above, you can be saving (₦
                {suggestedAmount?.toLocaleString()}) per{' '}
                {formik.values.savingsFrequency.toLowerCase() === 'daily'
                  ? 'day'
                  : formik.values.savingsFrequency.toLowerCase() === 'weekly'
                    ? 'week'
                    : 'month'}
                .
              </p>
            </div>
          )}

          <Input
            label="Preferred amount to save"
            options={[500, 1000, 1500, 5000, 10000]}
            onChange={handleAmountChange}
            required
          />
          {formik.touched.preferredAmount && formik.errors.preferredAmount && (
            <p className="mt-1 text-sm text-red-500">{formik.errors.preferredAmount}</p>
          )}

          {amountExceedsGoal && (
            <div className="flex gap-3 items-center p-2 rounded-md bg-[#FFF0F0] border border-red-400">
              <IoAlertCircle className="w-5 h-5 text-red-500" />
              <p className="text-xs text-red-600">
                Your preferred amount to save cannot be more than your savings goal.
              </p>
            </div>
          )}

          <div className="flex gap-3 items-center">
            <div
              className={`flex items-center cursor-pointer w-[40px] h-[20px] rounded-full transition-all 
              ${formik.values.autoWithdrawal ? 'bg-[#EFA005]' : 'bg-gray-300'}`}
              onClick={() => formik.setFieldValue('autoWithdrawal', !formik.values.autoWithdrawal)}
            >
              <div
                className={`w-[25px] h-[25px] bg-white rounded-full shadow-md transform transition-all 
                ${formik.values.autoWithdrawal ? 'translate-x-[15px]' : 'translate-x-0'}`}
              />
            </div>
            <span className="text-sm text-gray-700">
              Do you want to enable auto withdrawal at the end of your savings duration?
            </span>
          </div>

          <div className="flex gap-4 justify-end mt-6">
            <Button
              type="button"
              onClick={onClose}
              className="px-6 py-2 text-[#EFA005] bg-white border border-[#EFA005] rounded-md"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={!formik.isValid || !formik.dirty || amountExceedsGoal || isSubmitting}
              className={`px-6 py-2 text-white rounded-md ${
                !formik.isValid || !formik.dirty || amountExceedsGoal || isSubmitting
                  ? 'bg-gray-400' 
                  : 'bg-[#EFA005]'
              }`}
            >
              {isSubmitting ? "Processing..." : "Proceed"}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ReinvestModal; 
