import { cva, type VariantProps } from 'class-variance-authority';
import React from 'react';
import { cn } from '../../../utils/Index';
import LoadingIndicator from '../loading-indicator';

const buttonVariants = cva('font-medium py-2 px-4 rounded-lg shadow-sm', {
  variants: {
    variant: {
      default: 'bg-orange-1 hover:bg-orange-1/70 text-white duration-300 flex item-center justify-center',
      outlined:
        'bg-white hover:bg-orange-1/70 hover:text-white text-BrandPrimary duration-300 border-BrandPrimary border-[1px] item-center justify-center',
      links: 'bg-white hover:bg-white/70 text-dark-2 border border-gray-border',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

type ButtonVariantProps = VariantProps<typeof buttonVariants>;

interface ButtonProps extends ButtonVariantProps {
  onClick?: (e: React.FormEvent) => void;
  children: React.ReactNode;
  className?: string;
  loading?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
  disabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  onClick,
  children,
  className,
  type = 'button',
  variant,
  loading,
  disabled = false,
}) => (
  <button
    type={type}
    onClick={onClick}
    disabled={loading || disabled}
    className={cn(buttonVariants({ variant }), className, {
      '!bg-[#EEEEEE] !text-BrandGray700 cursor-not-allowed item-center justify-center':
        disabled,
    })}
  >
    {loading ? <LoadingIndicator size={20} /> : children}
  </button>
);

export default Button;
