import React from "react";
import Select from "react-select";

interface OptionType {
	value: string;
	label: string;
}

interface SelectInputProps {
  label: string;
  name: string;
  placeholder?: string;
  options: OptionType[];
  value: OptionType | null;
  onChange: (name: string, value: OptionType | null) => void;
  error?: string;
  required?: boolean;
}

const SelectInput: React.FC<SelectInputProps> = ({
  label,
  name,
  options,
  value,
  onChange,
  placeholder,
  error,
  required = false,
}) => {
  const handleSelectChange = (selectedOption: OptionType | null) => {
    onChange(name, selectedOption);
  };

  return (
    <div className={'w-full mb-4'}>
      <label className="block text-gray-500 font-medium mb-0" htmlFor={name}>
        {label}
        {required && <span className="text-red-error">*</span>}
      </label>
      <Select
        id={name}
        name={name}
        value={value}
        options={options}
        onChange={handleSelectChange}
        className="basic-multi-select"
        placeholder={placeholder}
        classNamePrefix="select"
        styles={{
          control: (provided) => ({
            ...provided,
            borderColor: error ? 'red' : provided.borderColor,
            padding: '4px 0px',
            marginTop: '2px',
            borderRadius: '8px',
          }),
        }}
      />
      {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
    </div>
  );
};

export default SelectInput;
